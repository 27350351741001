import React from 'react'
import left_image from '../assets/Images/Bonds-left-img.webp'


function ImplicationsofBonds() {
  return (
    <section>
    <div className='container py-8 md:py-12 xl:py-16 md:flex md:gap-6 xl:gap-16'>
            <div className=''>
                <img src={left_image}/>
            </div>
            <div className=' xl:w-9/12 flex flex-col justify-center mt-5 sm:mt-0'>
                <h1 className='nunito xl:text-[40px] text-2xl font-bold text-[#3C3C3C] xl:pb-10 xl:leading-tight'>Tax implications of Corporate Bonds</h1>
               <p className='inter text-base font-normal text-[#6A6A6A]'>The interest income earned from Corporate Bonds is taxable as per the income tax slab of the investor. If held until maturity, there is usually no capital gains tax applicable. However, if the bond is sold before maturity, any capital gains or losses would be subject to capital gains tax.</p>
            </div>
    </div>
   </section>
  )
}

export default ImplicationsofBonds