import React from 'react'
import img_1 from '../assets/Images/BenifitsOfshares-img.webp'

function BenifitOfShares() {
  return (
    <>
        <section className='typesofaif_bg'>
        <div className='container pt-12'>
                <h1 className='xl:text-4xl text-2xl lg:text-3xl font-bold nunito text-white text-center pb-2'>Benefits of Loan against MF/Shares</h1>
                <div className='grid md:grid-cols-3 gap-7 py-10 lg:py-16'>
                    <div className='flex flex-col justify-center w-full h-40 rounded-xl px-6 bg-[#D9D9D933] text-white'>
                        <p className='xl:text-3xl text-xl font-bold nunito'>Rapid Approval</p>
                        <p className='text-base font-normal inter'>Get Your Loan Approved Fast</p>
                    </div>
                    <div className='flex flex-col justify-center w-full h-40 rounded-xl px-6 bg-[#D9D9D933] text-white'>
                        <p className=' xl:text-3xl text-xl font-bold nunito'>Penalty-Free Prepayment</p>
                        <p className=' text-base font-normal inter'>Pay Back Early with Zero Penalties</p>
                    </div>
                    <div className='flex flex-col justify-center w-full h-40 rounded-xl px-6 bg-[#D9D9D933] text-white'>
                        <p className=' xl:text-3xl text-xl font-bold nunito'>Cost-Effective Borrowing</p>
                        <p className=' text-base font-normal inter'>Enjoy Lower Interest Rates</p>
                    </div>
                </div>
        </div>
        </section>

        <section className='lg:py-20 py-10'>
            <div className='container md:flex gap-10 justify-between'>
                <div className=''>
                    <img src={img_1}/>
                </div>
                <div className='flex flex-col justify-center items-center mt-8 sm:mt-0'>
                    <h3 className='xl:text-4xl text-2xl lg:text-3xl font-bold nunito text-[#3C3C3C]'>Who is eligible for a Loan Against Mutual Funds?</h3>
                    <p className='text-base font-normal inter text-[#6A6A6A] py-4'>Indian citizens aged 18 to 75 years with a credit score of 500 or more, or new to credit, can avail a loan against mutual funds. A single PAN card can have up to 2 loan accounts, one each against equity and debt mutual funds.</p>
                    <p className='text-base font-normal inter text-[#6A6A6A]'>The security list is reviewed monthly, and a large basket of mutual funds is covered, including open-ended schemes of all AMCs with a scheme AUM of INR 20 crores or more.</p>
                </div>
            </div>
        </section>
    </>
  )
}

export default BenifitOfShares