import React from 'react'
import img_1 from '../assets/Images/About-hero-section-1.webp'
import { Link } from 'react-router-dom'
function AboutHero() {
  return (
    <>
        <section className='about_hero xl:mt-3'>
            <div className='container py-10 xl:py-16'>
             <div className='xl:w-1/2'>
                <h1 className='lg:text-5xl text-2xl md:text-3xl  text-white font-bold nunito'>Your Trusted Partner</h1>
                <p className='lg:text-lg text-sm md:text-base py-2  text-white inter font-normal'>CHEERBULL is a new-age, holistic investment platform launched under the esteemed OIA Wealth. Our mission is to help our customers achieve financial freedom by providing a comprehensive range of financial products and services, all within one easy-to-use application.</p>
                <Link to="https://clientlogin.cheerbull.com/" target="_blank">
              <button className='sign_btn px-6 py-1.5 rounded-lg text-lg font-bold roboto bg-custom text-white'>
                <span>Get Started</span>
              </button>
            </Link>

             </div>
            </div>
        </section>
        <section className='container bg-white md:flex xl:gap-16 py-6 md:py-10 xl:py-16'>
                    {/* Left section */}
                    <div className='flex flex-col justify-center'>
                        <h1 className='xl:text-4xl text-2xl md:text-3xl font-bold nunito'>About Us</h1>
                        <p className='text-base font-normal text-[#6A6A6A] inter py-3'>Established in 2020 by a team of seasoned Chartered Accountants, OIA Wealth is a comprehensive Wealth Management firm committed to delivering exceptional financial advisory, investment, and wealth planning services. Our foundation is built on knowledge and integrity, ensuring that our interests are always aligned with those of our clients.</p>
                        <p className='text-base font-normal text-[#6A6A6A] inter'>Introducing "CheerBull," our cutting-edge DIY (Do-It-Yourself) digital platform. Leveraging the latest technology, CheerBull offers a seamless and fully digital investing and wealth creation experience, making investing easy and accessible for everyone, with or without an Advisor.</p>
                    </div>
                    {/* Right side */}
                    <div className='py-6 xl:w-4/5 xl:py-0'>
                        <img src={img_1} className=''/>
                    </div>
        </section>

    </>
  )
}

export default AboutHero