import React from 'react'

function BenefitsofDemat() {
  return (
    <section className='typesofaif_bg'>
    <div className='container py-12'>
            <h1 className='xl:text-4xl text-2xl md:text-3xl font-bold nunito text-white text-center pb-2'>Benefits of Equity & Demat</h1>
            <div className='grid md:grid-cols-3 gap-7 py-8 xl:py-16'>
                <div className='flex flex-col justify-center w-full h-48 md:h-64 xl:h-48 rounded-xl px-6 bg-[#D9D9D933] text-white'>
                    <p className='xl:text-3xl text-xl font-bold nunito'>Potential for higher returns</p>
                    <p className='text-base font-normal inter'>Historically, equities have provided higher average returns compared to other asset classes like bonds or cash investments.</p>
                </div>
                <div className='flex flex-col justify-center w-full h-48 md:h-64 xl:h-48 rounded-xl px-6 bg-[#D9D9D933] text-white'>
                    <p className=' xl:text-3xl text-xl font-bold nunito'>Diversification</p>
                    <p className=' text-base font-normal inter'>Investing in a diverse range of equity products can help spread risk across different sectors, industries, and geographies.</p>
                </div>
                <div className='flex flex-col justify-center w-full h-48 md:h-64 xl:h-48 rounded-xl px-6 bg-[#D9D9D933] text-white'>
                    <p className=' xl:text-3xl text-xl font-bold nunito'>Dividend income</p>
                    <p className=' text-base font-normal inter'>Many companies distribute a portion of their profits to shareholders in the form of dividends.</p>
                </div>
            </div>
    </div>
   </section>
  )
}

export default BenefitsofDemat