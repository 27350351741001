import React, { useState } from 'react';
import { Doughnut } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
} from 'chart.js';

ChartJS.register(ArcElement, Tooltip, Legend);

function InvestmentPlatformfor() {
  const [investmentAmount, setInvestmentAmount] = useState(100000); // Default value for demonstration
  const [returnRate, setReturnRate] = useState(5); // Default value for demonstration
  const [investmentPeriod, setInvestmentPeriod] = useState(5); // Default value for demonstration

  const calculateFinalAmount = () => {
    const rate = returnRate / 100;
    return investmentAmount * Math.pow(1 + rate, investmentPeriod);
  };

  const finalAmount = calculateFinalAmount();
  const returnAmount = finalAmount - investmentAmount;

  const data = {
    labels: ['Invested', 'Return'],
    datasets: [
      {
        data: investmentAmount > 0 ? [investmentAmount, returnAmount] : [1, 0],
        backgroundColor: ['#f57c00', '#ffcc80'],
        hoverBackgroundColor: ['#ef6c00', '#ffe0b2'],
      },
    ],
  };

  return (
    <>
      <section className='container fund-partners-bg-image xl:h-80'>
        <h1 className='nunito xl:text-5xl lg:text-3xl text-2xl pb-16 md:pb-24 xl:pb-0 font-bold text-center pt-16 text-white'>Invest in security calculators</h1>
      </section>
      <section className='container mt-6 sm:mt-0'>
        <div className='py-6 border-2 rounded-xl shadow-xl md:flex xl:absolute md:-mt-12 xl:-mt-40 bg-white'>
          {/* Left Side */}
          <div>
            <div className='py-8 px-4 xl:px-20 flex flex-col gap-12'>
              <div className='flex justify-between items-center gap-2'>
                <label className='nunito xl:text-2xl md:text-lg text-base font-bold'>Investment Amount</label>
                <div className='flex items-center border-2 rounded-lg'>
                  <input
                    type='number'
                    placeholder='Enter Amount'
                    className='px-2 xl:w-72 w-40 py-2 text-xl font-semibold text-[#2A2A2A] border-r-0 rounded-l-lg'
                    value={investmentAmount}
                    onChange={(e) => setInvestmentAmount(Number(e.target.value))}
                    style={{ textAlign: 'left' }}
                  />
                  <span className='px-2 py-2 bg-gray-200 text-xl font-semibold text-[#2A2A2A] rounded-r-lg'>₹</span>
                </div>
              </div>
              <input
                type='range'
                className='w-full -mt-10 cursor-pointer'
                min="0"
                max="10000000"
                value={investmentAmount}
                onChange={(e) => setInvestmentAmount(Number(e.target.value))}
                style={{ accentColor: '#014579' }}
              />
              <div className='flex justify-between items-center gap-2'>
                <label className='nunito xl:text-2xl md:text-lg text-base font-bold'>Expected return rate (p.a)</label>
                <div className='flex items-center border-2 rounded-lg'>
                  <input
                    type='number'
                    placeholder='Enter Rate'
                    className='px-2 xl:w-72 w-40 py-2 text-xl font-semibold text-[#2A2A2A] border-r-0 rounded-l-lg'
                    value={returnRate}
                    onChange={(e) => setReturnRate(Number(e.target.value))}
                    style={{ textAlign: 'left' }}
                  />
                  <span className='px-2 py-2 bg-gray-200 text-xl font-semibold text-[#2A2A2A] rounded-r-lg'>%</span>
                </div>
              </div>
              <input
                type='range'
                className='w-full -mt-10 cursor-pointer'
                min="0"
                max="100"
                value={returnRate}
                onChange={(e) => setReturnRate(Number(e.target.value))}
                style={{ accentColor: '#014579' }}
              />
              <div className='flex justify-between items-center gap-2'>
                <label className='nunito xl:text-2xl md:text-lg text-base font-bold'>Investment Period</label>
                <div className='flex items-center border-2 rounded-lg'>
                  <input
                    type='number'
                    placeholder='Enter Period'
                    className='px-2 xl:w-72 w-40 py-2 text-xl font-semibold text-[#2A2A2A] border-r-0 rounded-l-lg'
                    value={investmentPeriod}
                    onChange={(e) => setInvestmentPeriod(Number(e.target.value))}
                    style={{ textAlign: 'left' }}
                  />
                  <span className='px-2 py-2 bg-gray-200 text-xl font-semibold text-[#2A2A2A] rounded-r-lg'>Yr</span>
                </div>
              </div>
              <input
                type='range'
                className='w-full -mt-10 cursor-pointer'
                min="0"
                max="50"
                value={investmentPeriod}
                onChange={(e) => setInvestmentPeriod(Number(e.target.value))}
                style={{ accentColor: '#014579' }}
              />
            </div>
            {/* Amounts and Return show here */}
            <div className='xl:px-20 px-4 grid xl:grid-cols-3 md:grid-cols-2 gap-3'>
              <div className='flex flex-col gap-2'>
                <p className='nunito font-medium text-lg text-[#7B7B7B]'>Investment Amount</p>
                <div className='bg-[#FFF7F7] border border-[#FFDCDC] rounded-xl xl:h-10 flex justify-center items-center xl:text-xl text-lg font-bold text-[#2A2A2A]'>
                  ₹ {investmentAmount.toLocaleString()}
                </div>
              </div>
              <div className='flex flex-col gap-2'>
                <p className='nunito font-medium text-lg text-[#7B7B7B]'>Expected Future Value</p>
                <div className='bg-[#FFF7F7] border border-[#FFDCDC] rounded-xl xl:h-10 flex justify-center items-center xl:text-xl text-lg font-bold text-[#2A2A2A]'>
                  ₹ {finalAmount.toLocaleString()}
                </div>
              </div>
              <div className='flex flex-col gap-2'>
                <p className='nunito font-medium text-lg text-[#7B7B7B]'>Estimated Returns</p>
                <div className='bg-[#FFF7F7] border border-[#FFDCDC] rounded-xl xl:h-10 flex justify-center items-center xl:text-xl text-lg font-bold text-[#2A2A2A]'>
                  ₹ {returnAmount.toLocaleString()}
                </div>
              </div>
            </div>
          </div>
          {/* Right Side */}
          <div className='xl:w-2/5 md:w-1/2 xl:mr-16 mt-12 sm:mt-0'>
            <Doughnut data={data} />
          </div>
        </div>
      </section>
    </>
  );
}

export default InvestmentPlatformfor;
