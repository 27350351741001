import React from 'react'
import right_img from '../assets/Images/Corparate-bond-hero-right.webp'
import { Link } from 'react-router-dom'
function CorporateBondsHero() {
  return (
    <>
        <section className='corporate_bonds'>
            <div className='container py-10 xl:py-20 xl:mt-3'>
            <div className='xl:w-1/2 md:w-9/12'>
                <h1 className='text-white nunito font-bold xl:text-5xl text-3xl pb-3'>Corporate Bonds</h1>
                <p className='text-white inter font-normal xl:text-lg pb-4'>Corporate Bonds are debt instruments issued by corporations to raise capital from investors. Adding corporate bonds to your portfolio can be beneficial in several ways. They offer a fixed income stream through regular interest payments, making them a stable and reliable investment option. Corporate Bonds also provide potential wealth creation as their market value can increase over time.</p>
                <Link to="https://clientlogin.cheerbull.com/" target="_blank">
              <button className='sign_btn px-6 py-1.5 rounded-lg text-lg font-bold roboto bg-custom text-white'>
                <span>Get Started</span>
              </button>
            </Link>

                </div>
            </div>
        </section>
           {/* Second part */}
            <section className='container md:flex gap-3 py-8 md:py-16'>
                <div className='xl:w-3/5 md:w-full flex flex-col justify-center'>
                    <h2 className='nunito text-[#3C3C3C] xl:text-4xl lg:text-3xl text-2xl font-bold '>What are Corporate Bonds?</h2>
                    <p className='text-[#6A6A6A] inter font-base font-normal py-3'>Corporate Bonds are loans made by investors to corporations in exchange for regular interest payments and the return of the principal amount at maturity. They have a fixed maturity date and an agreed-upon interest rate. Corporations issue bonds to fund various activities such as expansions, acquisitions, or debt refinancing.</p>
                    <p className='text-[#6A6A6A] inter font-base font-normal '>Corporate bonds can be issued by both public and private companies and are typically traded in the bond market.</p>
                </div>
                <div className='xl:w-2/5 mt-8 sm:mt-0'>
                    <img src={right_img}/>
                </div>
            </section>
    </>
  )
}

export default CorporateBondsHero