import React from 'react'
import right_img from '../assets/Images/AIF-hero-right-img.svg'
import { Link } from 'react-router-dom'

function AlternativeFundsHero() {
  return (
    <>  
        {/* First part */}
        <section className='AlternativeFundsHero_bg'>
            <div className='container py-10 xl:py-20 xl:mt-3 '>
            <div className='xl:w-1/2 md:w-3/4'>
                <h1 className='text-white nunito font-bold text-2xl lg:text-3xl xl:text-5xl pb-3'>Alternative Investment<br/> Funds (AIF)</h1>
                <p className='text-white inter font-normal xl:text-lg pb-6'>Expand your investment portfolio by incorporating Alternative Investment Funds (AIFs) that offer access to unconventional and distinctive investment prospects. AIFs encompass a diverse range of sectors such as real estate, private equity, infrastructure.</p>
                <Link to="https://clientlogin.cheerbull.com/" target="_blank">
              <button className='sign_btn px-6 py-1.5 rounded-lg text-lg font-bold roboto bg-custom text-white'>
                <span>Get Started</span>
              </button>
            </Link>

            </div>
            </div>
        </section>
        {/* Second part */}
        <section className='container md:flex gap-3 py-10 xl:py-16'>
            <div className='md:w-3/4 flex flex-col justify-center'>
                <h2 className='nunito text-[#3C3C3C] xl:text-4xl text-2xl lg:text-3xl font-bold '>What are Alternative Investment Funds?</h2>
                <p className='text-[#6A6A6A] inter font-base font-normal py-4'>Alternative Investment Funds (AIF), are privately pooled investment funds that operate outside of traditional investment vehicles like mutual funds. They cater to sophisticated investors and offer alternative investment opportunities beyond the usual equity and debt instruments. AIFs could include private equity funds, real estate funds, hedge funds, venture capital funds, and infrastructure funds.</p>
                <p className='text-[#6A6A6A] inter font-base font-normal '>These funds are regulated by the Securities and Exchange Board of India (SEBI) and are subject to certain investment restrictions and disclosure requirements.</p>
            </div>
            <div className='mt-6 sm:mt-0'>
                <img src={right_img}/>
            </div>
        </section>
    </>
  )
}

export default AlternativeFundsHero