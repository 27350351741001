import React from 'react'
import AlternativeFundsHero from '../Components/AlternativeFundsHero'
import TypesofAif from '../Components/TypesofAif'
import WhyAif from '../Components/WhyAif'
import JoinUs from '../Components/JoinUs'
import FeaturedOn from '../Components/FeaturedOn'
import Testimonials from '../Components/Testimonials'
import FeaturedOnTwo from '../Components/FeaturedOnTwo'
import AifFaq from '../Components/AifFaq'
import OurProductInputForm from '../Components/OurProductInputForm'


function AlternativeFunds() {
  return (
    <>
       
        <AlternativeFundsHero/>
        <TypesofAif/>
        <WhyAif/>
        <OurProductInputForm/>
        <JoinUs/>
        {/* <FeaturedOnTwo/> */}
        <AifFaq/>
        <Testimonials/>
      
    </>
  )
}

export default AlternativeFunds