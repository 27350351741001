import React, { useState, useRef, useEffect } from 'react';

const Accordion = ({ question, answer, isOpen, onClick }) => {
  const [height, setHeight] = useState("0px");
  const content = useRef(null);

  useEffect(() => {
    setHeight(isOpen ? `${content.current.scrollHeight}px` : "0px");
  }, [isOpen]);

  return (
    <div className={`border-b rounded-lg border-[#C3C3C3] w-full ${isOpen ? 'bg-white' : ''}`}>
      <button
        className="flex justify-between w-full py-4 lg:py-8 px-4 lg:px-8 text-left focus:outline-none"
        onClick={onClick}
      >
        <span className="lg:text-2xl text-lg text-[#3C3C3C] nunito font-bold">{question}</span>
        <svg
          className={`w-6 h-6 transform transition-transform duration-300 ${isOpen ? 'rotate-180' : 'rotate-0'}`}
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"></path>
        </svg>
      </button>
      <div
        ref={content}
        style={{ maxHeight: `${height}` }}
        className="overflow-hidden transition-max-height duration-300 ease-in-out"
      >
        <div className="py-4 lg:py-6 px-4 lg:px-8 text-base inter text-[#6A6A6A]">{answer}</div>
      </div>
    </div>
  );
};

const  GoldFaq = () => {
  const [openIndex, setOpenIndex] = useState(null);

  const faqs = [
    {
      question: "What is the minimum and maximum amount one can invest in SGBs?",
      answer: "The minimum limit for investment in SGBs is 1 gram of gold, and there is no maximum limit, subject to a maximum limit of 4 kilograms for individuals and HUFs and 20 kilograms for trusts and other institutions in a single financial year.",
    },
    {
      question: " How can I purchase Sovereign Gold Bonds?",
      answer: "SGBs can be bought through select banks, post offices, and online trading platforms. The investors can buy them during the issue period announced by RBI or from the secondary market.",
    },
    {
      question: "What is the tenure of Sovereign Gold Bonds?",
      answer: "The tenure of the Sovereign Gold Bonds will be 8 years from the date of issue. However, the investor will have an active option of exit from the 5th year onwards either through redemption or selling them on the secondary market.",
    },
    {
      question: "Are Sovereign Gold Bonds transferable?",
      answer: "Yes, Sovereign Gold Bonds are transferable. Investors are allowed to transfer their bonds to other individuals or entities subject to prescribed regulations and procedures by the RBI.",
    },
    {
      question: "What happens if I redeem my SGBs before maturity?",
      answer: "If you redeem your SGBs prematurely, before maturity, after the fifth year, you will get the prevailing market price of gold at the time of redemption. The interest earned up to the date of redemption shall be paid along with the principal amount.",
    },
    // Add more FAQs as needed
  ];

  const handleToggle = index => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <section className='bg-[#F5F5F5]'>
        <div className='container lg:py-16 py-8'>
            <div className="">
            <h2 className="xl:text-4xl text-2xl text-[#3C3C3C] nunito font-bold text-center pb-6">Frequently Asked Questions</h2>
            {faqs.map((faq, index) => (
                <Accordion
                  key={index}
                  question={faq.question}
                  answer={faq.answer}
                  isOpen={openIndex === index}
                  onClick={() => handleToggle(index)}
                />
            ))}
            </div>
        </div>
    </section>
  );
};

export default  GoldFaq;
