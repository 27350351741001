import React from "react";
import google from "../assets/Icons/google-play-store-icon.svg";
import ios from "../assets/Icons/ios-app-icon.svg";
import mobile from "../assets/Images/Mobile-img.svg";
import logo from "../assets/Icons/cheerbull-logo.png";
import linkdin from "../assets/Icons/Linkedin.svg";
import facebook from "../assets/Icons/Facebook.svg";
import Twitter from "../assets/Icons/Twitter.svg";
import Youtube from "../assets/Icons/Youtube.svg";
import fotter_logo from "../assets/Icons/Fotter_logo.svg";
import { Link, NavLink } from "react-router-dom";

function Fotter() {
  return (
    <>
      <section className="bg-black hidden lg:block ">
        <div className="container py-20 flex justify-between relative ">
          {/* left side */}
          <div className="flex justify-center items-center gap-5 lg:w-2/5">
            <h1 className="lg:text-3xl xl:text-4xl font-extrabold nunito text-white">
              Download One of India's Best Investment Apps CHEERBULL
            </h1>
            <div className="flex flex-col gap-4">
            <a target="_blank" href="https://play.google.com/store/apps/details?id=com.cheerbull">
              <img src={google} className="lg:w-72 xl:w-96 object-cover" />
            </a>
            <a target="_blank" href="https://apps.apple.com/in/app/cheerbull/id6651834436">
              <img src={ios} className="lg:w-72 xl:w-96 object-cover" />
            </a>
            </div>
          </div>

          {/* right side */}
          <div className="">
            <img src={mobile} className="absolute xl:right-24 lg:right-0 lg:-top-[90px] xl:-top-28" />
          </div>
        </div>
      </section>
      <section className="hidden lg:block container xl:px-72 lg:px-28">
        <div className=" bg-white xl:w-[977px] lg:w-[800px] lg:h-52 rounded-2xl flex justify-center items-center -mt-12 absolute  shadow-xl ">
          <div className="grid grid-cols-3 gap-8 xl:ml-10 px-16">
            <div className="">
              <h1 className="lg:text-2xl xl:text-3xl nunito font-bold text-black">
                Sign up to receive our latest news
              </h1>
            </div>
            <div>
              <input
                placeholder="Enter your email"
                className="mb-1.5 border-2 border-[#C4C4C4] rounded-lg py-3 lg:px-4 xl:px-10"
              />
              <p className="text-[10px] font-normal text-[#CBCBCB]">
                Get an update every week - no spam.
              </p>
            </div>
            <div>
              <button className="sign_btn bg-custom text-xl font-medium asap text-white rounded-lg py-3 px-10">
                <span>Subscribe</span>
              </button>
            </div>
          </div>
        </div>
      </section>
      {/* Fotter */}
      <section className="bg-[#F5F5F5] hidden lg:block">
        <div className="container pt-60 pb-12 flex justify-between ">
          {/* Left part */}
          <div className="w-2/5">
          <Link to={'/'}> <img src={logo} /></Link>
           
            <p className="text-base text-[#6a6a6a] inter font-normal py-4">
            CHEERBULL is the name   of our integrated investing platform of OIA Wealth Private Limited enabling   easy investing for all, offerring multiple products under one roof
            </p>
            {/* Social Media icon */}
            <div className="flex gap-4">
              <img src={linkdin} />
              <img src={facebook} />
              <img src={Twitter} />
              <img src={Youtube} />
            </div>
          </div>

          {/* Right part */}
          <div className="flex gap-28">
            <div className="flex flex-col gap-4">
              <h2 className="nunito text-lg font-bold">Investment Services</h2>
              <NavLink to={'/mutual-funds'} className="inter font-normal text-sm text-[#6C6363] leading-7">
                Mutual Funds
              </NavLink>
              <NavLink to={'/pms'} className="inter font-normal text-sm text-[#6C6363] leading-7">
                Portfolio Management Services (PMS)
              </NavLink>
              <NavLink to={'/aif'} className="inter font-normal text-sm text-[#6C6363] leading-7">
                Alternative Investment Funds (AIF)
              </NavLink>
              <NavLink to={'/mld'} className="inter font-normal text-sm text-[#6C6363] leading-7">
                Market-Linked Debentures
              </NavLink>
              <NavLink to={'/corporate-fd'} className="inter font-normal text-sm text-[#6C6363] leading-7">
              Fixed Deposit
              </NavLink>
              <NavLink to={'/corporate-bonds'} className="inter font-normal text-sm text-[#6C6363] leading-7">
              Corporate Bonds
              </NavLink>
              <NavLink to={'/insurance'} className="inter font-normal text-sm text-[#6C6363] leading-7">
              Insurance
              </NavLink>
              <NavLink to={'/soverign-bonds'} className="inter font-normal text-sm text-[#6C6363] leading-7">
              Sovereign Gold Bonds (SGBs)
              </NavLink>
              <NavLink to={'/tax-free-bonds'} className="inter font-normal text-sm text-[#6C6363] leading-7">
              Tax Free Bonds
              </NavLink>
              <NavLink to={'/demat-page'} className="inter font-normal text-sm text-[#6C6363] leading-7">
              Equity & Demat
              </NavLink>
              <NavLink to={'/againt-shares'} className="inter font-normal text-sm text-[#6C6363] leading-7">
              Loan Against Mutual Funds
              </NavLink>
              <NavLink to={'/p2p'} className="inter font-normal text-sm text-[#6C6363] leading-7">
              Peer-to-Peer Lending (P2P)
              </NavLink>
              {/* <p className="inter font-normal text-sm text-[#6C6363] leading-7">
                Tax Free Bonds
              </p>
              <p className="inter font-normal text-sm text-[#6C6363] leading-7">
                High Yield Bonds
              </p>
              <p className="inter font-normal text-sm text-[#6C6363] leading-7">
                Demat & Trading
              </p>
              <p className="inter font-normal text-sm text-[#6C6363] leading-7">
                Equity Intraday Trading
              </p>
              <p className="inter font-normal text-sm text-[#6C6363] leading-7">
                Futures and options
              </p>
              <p className="inter font-normal text-sm text-[#6C6363] leading-7">
                Equity Cash Market
              </p>
              <p className="inter font-normal text-sm text-[#6C6363] leading-7">
                Exchange Traded Funds (ETF)
              </p> */}
            </div>
            <div className="flex flex-col gap-4">
              <h3 className="nunito text-lg font-bold">Financial Services</h3>
              <NavLink to="/success-will" className="inter font-normal text-sm text-[#6C6363] leading-7">
              Succession & Will
              </NavLink>
              <NavLink to="/comming-soon" className="inter font-normal text-sm text-[#6C6363] leading-7">
              Goal Calculators
              </NavLink>
              <NavLink to="/#" className="inter font-normal text-sm text-[#6C6363] leading-7">
              Track Portfolio Free!!
              </NavLink>
            </div>
            <div className="flex flex-col gap-4">
              <div className="nunito text-lg font-bold ">Company</div>
              <div>
                <NavLink to="/about-us" className="inter font-normal text-sm text-[#6C6363] leading-7">
                  About Us
                </NavLink>
              </div>
              {/* <p className="inter font-normal text-sm text-[#6C6363] leading-7">
                Our Services
              </p> */}
              <div>
                <NavLink to="/contact" className="inter font-normal text-sm text-[#6C6363] leading-7">
                  Contact Us
                </NavLink>
              </div>
            </div>
          </div>
        </div>
        {/* Disclaimer: */}
        <div className="container border-t border-[#C3C3C3] pt-6 pb-6">
        <div className="flex gap-1 pb-4">
         <img src={fotter_logo}/>
          <p className="text-base font-semibold plus-jakarta-sans text-[#6A6A6A]">- OIA Wealth Private Limited</p>
        </div>
          <p className="inter text-base font-normal text-[#6A6A6A]">
            <span className="font-bold pr-2">Disclaimer:</span>
           AMFI Registered   Mutual Fund Distributor. ARN Number: 174028, Valid till 19/06/2027 Original   ARN issue date: 24/12/2020
          </p>
          <p className="inter text-base font-normal text-[#6A6A6A] py-1">
          Mutual funds & Securities are subject to market risks, please read all your scheme/securities-related documents carefully before investing. 
          </p>
          {/* <p className="inter text-base font-normal text-[#6A6A6A] py-3">
            InCred Premier (Mvalu Technology Services Private Limited) of
            distributor for Products such as PMS, AIF, OFS, NCD etc
          </p> */}
          {/* <p className="inter text-base font-normal text-[#6A6A6A] py-3">
          Guided view is indicative in nature and is based on the information provided by the user voluntarily. Each user of this information should make such an investigation as it deems necessary to arrive at an independent evaluation of an investment in the securities of companies referred here (including the merits and risks involved) and should consult his own advisors to determine the merits and risks of such investment.
          </p> */}
          <p className="inter text-base font-normal text-[#6A6A6A]">
            <span className="font-bold">
              Registered Office :
            </span>{" "}
           406 Gurunanak Plaza, Lodha Kothi, Station Road, Kota 324002, Rajasthan
          </p>
          <p className="inter text-base font-normal text-[#6A6A6A]">
            <span className="font-bold">
             Corporate Office Address :
            </span>{" "}
            Spark & Associates, F- 371, Indra Vihar, Kota 324005, Rajasthan
          </p>
          <p className="inter text-base font-normal text-[#6A6A6A]">
            <span className="font-bold">CIN :</span> U67190RJ2020PTC072392
          </p>
        </div>
              {/* CopyRight © 2024. All Rights Reserved. */}
             <div className="border-t border-[#C3C3C3] ">
              <p className="container inter text-sm font-normal text-[#898989] pt-4 pb-24">Copyright © 2024 OIA Wealth Private Limited</p>
             </div>
      </section>
      {/* For Mobile Fotter  */}
      <section className="lg:hidden block bg-black">
        <div className="container pt-8 flex gap-6">
          <div className="w-1/2 block md:hidden">
            <h1 className="text-white text-lg font-extrabold nunito leading-6">
              Download One of India's Best Trading Apps
            </h1>
            <div className="flex gap-2 mt-2">
            <a target="_blank" href="https://play.google.com/store/apps/details?id=com.cheerbull">
              <img src={google} className=" h-5" />
            </a>
            <a target="_blank" href="https://apps.apple.com/in/app/cheerbull/id6651834436">
              <img src={ios} className=" h-5" />
            </a>
            </div>
          </div>
             {/* For Tablet Download section */}
             <div className="md:w-3/4  sm:flex flex-col gap-4 justify-center items-center hidden md:block">
             <div>
                <h1 className="text-white text-3xl lg:text-4xl font-extrabold nunito leading-8">
                  Download One of India's Best Trading Apps
                </h1>
             </div>
            <div className="flex gap-2 mt-2">
              <img src={google} className=" h-5" />
              <img src={ios} className=" h-5" />
            </div>
          </div>

          <div className="w-1/2 md:w-1/4">
            <img src={mobile} className="object-cover h-fit" />
          </div>
        </div>
      </section>

      <section className="lg:hidden block">
        <div className="container py-8 ">
          <h1 className="text-lg font-bold pb-3 nunito leading-5">
            Sign up to receive <br /> our latest news
          </h1>
          <div className="flex gap-2">
            <div>
              <input
                placeholder="Enter your email"
                className="py-2 px-3 rounded-lg border border-[#C3C3C3]"
              />
            </div>
            <button className=" bg-custom py-2 px-3 rounded-lg text-white text-start">
              Subscribe
            </button>
          </div>
          <p className="inter font-normal text-[8px] pt-1 text-[#CBCBCB]">
            Get an update every week - no spam.
          </p>
        </div>
      </section>
      <section className="lg:hidden block bg-[#F5F5F5]">
        <div className="container py-5">
          <div className="flex gap-7">
             <Link to="/"><img src={logo} className=" h-28" /></Link>
            <div className="flex flex-col gap-4 w-3/5">
              <p className="text-xs font-normal text-[#6A6A6A] inter">
                CHEERBULL is the name   of our integrated investing platform of OIA Wealth Private Limited enabling   easy investing for all, offerring multiple products under one roof
              </p>
              <div className="flex gap-2">
                <img src={linkdin} className="h-6 w-8" />
                <img src={facebook} className="h-6 w-8" />
                <img src={Twitter} className="h-6 w-8" />
                <img src={Youtube} className="h-6 w-8" />
              </div>
            </div>
          </div>
          {/* Links */}
          <div className="flex justify-between py-8">
            <ul className="w-7/12 flex flex-col gap-2">
              <li className="nunito text-base font-bold text-[#343434]">
                Investment Services
              </li>
              <NavLink to={'/mutual-funds'} className="inter text-xs font-normal text-[#6C6363] ">
                Mutual Funds
              </NavLink>
              <NavLink to={'/pms'} className="inter text-xs font-normal text-[#6C6363] ">
                Portfolio Management Services (PMS)
              </NavLink>
              <NavLink to={'/aif'} className="inter text-xs font-normal text-[#6C6363] ">
                Alternative Investment Funds (AIF)
              </NavLink>
              <NavLink to={'/mld'} className="inter text-xs font-normal text-[#6C6363] ">
                Market-Linked Debentures
              </NavLink>
              {/* <li className="inter text-xs font-normal text-[#6C6363] ">
                Unlisted Equities
              </li>
              <li className="inter text-xs font-normal text-[#6C6363] ">
                Pre-IPO
              </li>
              <li className="inter text-xs font-normal text-[#6C6363] ">
                ESOP
              </li> */}
              
              <NavLink to={'/corporate-fd'} className="inter text-xs font-normal text-[#6C6363] ">
              Fixed Deposit
              </NavLink>
              <NavLink to={'/corporate-bonds'} className="inter text-xs font-normal text-[#6C6363] ">
                Corporate Bonds
              </NavLink>
              <NavLink to={'/insurance'} className="inter text-xs font-normal text-[#6C6363] ">
              Insurance
              </NavLink>
              <NavLink to={'/soverign-bonds'} className="inter text-xs font-normal text-[#6C6363] ">
                Sovereign Gold Bonds (SGBs)
              </NavLink>
              <NavLink to={'/tax-free-bonds'} className="inter text-xs font-normal text-[#6C6363] ">
                Tax Free Bonds
              </NavLink>
              
              <NavLink  to={'/demat-page'} className="inter text-xs font-normal text-[#6C6363] ">
              Equity & Demat
              </NavLink>
              <NavLink to={'/againt-shares'} className="inter text-xs font-normal text-[#6C6363] ">
              Loan Against Mutual Funds
              </NavLink>
              <NavLink to={'/p2p'} className="inter text-xs font-normal text-[#6C6363] ">
                Peer-to-Peer Lending (P2P)
              </NavLink>
            
            </ul>
            <div>
              <ul className="flex flex-col gap-2">
                <li className="nunito text-base font-bold text-[#343434]">
                  Financial Services
                </li>
                <NavLink to="/success-will" className="inter text-xs font-normal text-[#6C6363]">
                Succession & Will
                </NavLink>
                <NavLink to="/comming-soon" className="inter text-xs font-normal text-[#6C6363]">
                Goal Calculators
                </NavLink>
                <NavLink to="/#" className="inter text-xs font-normal text-[#6C6363]">
                Track Portfolio Free!!
                </NavLink>
              </ul>
              <ul className="flex flex-col gap-2 pt-3">
                <li className="nunito text-base font-bold text-[#343434]">
                  Company
                </li>
                <NavLink to="/about-us" className="inter text-xs font-normal text-[#6C6363]">
                  About Us
                </NavLink>
                <NavLink to="/contact" className="inter text-xs font-normal text-[#6C6363]">
                  Contact Us
                </NavLink>
              </ul>
            </div>
          </div>
          <div className="border-t border-[#C3C3C3]">
              <div className="flex gap-1 pt-5">
               <img src={fotter_logo}/>
              <p className="text-sm font-semibold plus-jakarta-sans text-[#6A6A6A]">- OIA Wealth Private Limited</p>
            </div>
            <p className="inter text-xs font-normal text-[#6A6A6A] pt-5">
              <span className="font-bold pr-1">Disclaimer:</span>
            AMFI Registered   Mutual Fund Distributor. ARN Number: 174028, Valid till 19/06/2027 Original   ARN issue date: 24/12/2020
            </p>
            <p className="inter text-xs font-normal text-[#6A6A6A] py-2">
            Mutual funds & Securities are subject to market risks, please read all your scheme/securities-related documents carefully before investing.
            </p>
            
            {/* <p className="inter text-xs font-normal text-[#6A6A6A] py-2">
              Guided view is indicative in nature and is based on the
              information provided by the user voluntarily. Each user of this
              information should make such an investigation as it deems
              necessary to arrive at an independent evaluation of an investment
              in the securities of companies referred here (including the merits
              and risks involved) and should consult his own advisors to
              determine the merits and risks of such investment.
            </p> */}
            <p className="inter text-sm font-normal text-[#6A6A6A]">
            <span className="font-bold">
              Registered Office :
            </span>{" "}
           406 Gurunanak Plaza, Lodha Kothi, Station Road, Kota 324002, Rajasthan
          </p>
          <p className="inter text-sm font-normal text-[#6A6A6A] py-2">
            <span className="font-bold">
             Corporate Office Address :
            </span>{" "}
            Spark & Associates, F- 371, Indra Vihar, Kota 324005, Rajasthan
          </p>
          <p className="inter text-sm font-normal text-[#6A6A6A]">
            <span className="font-bold">CIN :</span> U67190RJ2020PTC072392
          </p>
            {/* CopyRight © 2024. All Rights Reserved. */}
            <div className="border-t border-[#C3C3C3] ">
              <p className=" inter text-sm font-normal text-[#898989] pt-4 pb-16 md:pb-20 lg:pb-16 xl:pb-10">Copyright © 2024 OIA Wealth Private Limited</p>
             </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Fotter;
