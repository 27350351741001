 import React, { useState, useRef, useEffect } from 'react';

const Accordion = ({ question, answer, isOpen, onClick }) => {
  const [height, setHeight] = useState("0px");
  const content = useRef(null);

  useEffect(() => {
    setHeight(isOpen ? `${content.current.scrollHeight}px` : "0px");
  }, [isOpen]);

  return (
    <div className={`border-b rounded-lg border-[#C3C3C3] w-full ${isOpen ? 'bg-white' : ''}`}>
      <button
        className="flex justify-between w-full py-4 lg:py-8 px-4 lg:px-8 text-left focus:outline-none"
        onClick={onClick}
      >
        <span className="lg:text-2xl text-lg text-[#3C3C3C] nunito font-bold">{question}</span>
        <svg
          className={`w-6 h-6 transform transition-transform duration-300 ${isOpen ? 'rotate-180' : 'rotate-0'}`}
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"></path>
        </svg>
      </button>
      <div
        ref={content}
        style={{ maxHeight: `${height}` }}
        className="overflow-hidden transition-max-height duration-300 ease-in-out"
      >
        <div className="py-4 lg:py-6 px-4 lg:px-8 text-base inter text-[#6A6A6A]">{answer}</div>
      </div>
    </div>
  );
};

const  CarporateBondFaq = () => {
  const [openIndex, setOpenIndex] = useState(null);

  const faqs = [
    {
      question: "How do corporate bonds differ from government bonds? ",
      answer: "Corporate bonds are issued by companies, while government bonds are directly issued by government entities. Compared with a government bond, a corporate bond commands a high interest rate because of the former's higher associated risk.",
    },
    {
      question: "What are the risks associated with corporate bonds? ",
      answer: "Some of the risks involved are credit, interest rate, and liquidity risks. Generally, highly rated bonds show less risk, and high yields, typically, indicate an increased amount of risk.",
    },
    {
      question: " What factors should I consider before investing in corporate bonds? ",
      answer: "Consider the issuer's credit rating, maturity of the bond, yield, interest rate environment, and your risk tolerance and investment horizon.",
    },
    {
      question: " Can corporate bonds be traded in the secondary market?",
      answer: "Yes, corporate bonds can be traded in the secondary market. In other words, they can be resold or bought prior to their maturity date..",
    },
    {
      question: "Are corporate bond interest payments taxable? ",
      answer: "Yes, all the interest payments on the corporate bonds are usually subject to income tax. Inquire for more details from a specific tax advisor.",
    },
    // Add more FAQs as needed
  ];

  const handleToggle = index => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <section className='bg-[#F5F5F5]'>
        <div className='container lg:py-16 py-8'>
            <div className="">
            <h2 className="xl:text-4xl text-2xl text-[#3C3C3C] nunito font-bold text-center pb-6">Frequently Asked Questions</h2>
            {faqs.map((faq, index) => (
                <Accordion
                  key={index}
                  question={faq.question}
                  answer={faq.answer}
                  isOpen={openIndex === index}
                  onClick={() => handleToggle(index)}
                />
            ))}
            </div>
        </div>
    </section>
  );
};

export default  CarporateBondFaq;
