import React from 'react'

function PartnerJoinUs() {
  return (
    <section className='tryour_bg'>
        <div className='container py-16'>
            <h1 className='text-white pb-2 text-2xl md:text-3xl xl:text-5xl font-bold nunito text-center'>Join Us(Become a Partner today line)   </h1>
            <p className='inter text-center xl:px-52 text-base font-normal text-white'>Access the best and comprehensive Service Suite and products for your financial goals. Be up to date with your aggregate portfolio, and execute transactions seamlessly with no extra charges.</p>
           <div className='flex justify-center pt-10'>
                <button className='bg-custom sign_btn py-3 px-8 text-lg font-bold roboto text-white rounded-lg '>
                <span>Register Now</span></button>
           </div>
        </div>
    </section>
  )
}

export default PartnerJoinUs