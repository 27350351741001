import React from 'react'

function TypesofMLD() {
  return (
    <section className='typesofaif_bg'>
        <div className='container xl:pt-16 py-12 xl:py-0'>
            <h1 className='xl:text-4xl text-2xl lg:text-3xl font-bold nunito text-white text-center pb-2'>Types of Market-Linked Debentures</h1>
            <p className='text-white font-normal text-center inter'>While specific types of MLDs are not mentioned in the provided information, market-linked<br/> debentures can have various structures and underlying assets.</p>
            <div className='md:flex gap-8 justify-between md:py-12'>
                <div className='w-full xl:h-44 rounded-xl flex flex-col justify-center my-10 sm:my-0 py-6 xl:py-0 px-8 bg-[#D9D9D933] text-white'>
                    <p className='xl:text-3xl text-xl font-bold nunito'>Principal Protected MLDs</p>
                    <p className='inter text-base font-normal'>These MLDs provide investors with a level of capital protection. They guarantee the return of the initial investment amount at maturity, regardless of the performance of the underlying market index.</p>
                </div>
                <div className='w-full xl:h-44 rounded-xl flex flex-col justify-center my-10 sm:my-0 py-6 xl:py-0 px-8 bg-[#D9D9D933] text-white'>
                    <p className='xl:text-3xl text-xl font-bold nunito'>Non-Principal Protected MLDs</p>
                    <p className='inter text-base font-normal'>Unlike principal protected MLDs, these MLDs do not guarantee the return of the initial investment amount at maturity. The returns of these MLDs are directly linked to the performance of the underlying market index,</p>
                </div>
            </div>
        </div>
    </section>
  )
}

export default TypesofMLD