import React from 'react';
import { BrowserRouter, Routes, Route} from 'react-router-dom'
import HomePage from './Pages/HomePage';
import AboutPage from './Pages/AboutPage';
import MutualFunds from './Pages/MutualFunds';
import PortfolioPMS from './Pages/PortfolioPMS';
import AlternativeFunds from './Pages/AlternativeFunds';
import MarketLinked from './Pages/MarketLinked';
import CorporateFD from './Pages/CorporateFD';
import CorporateBonds from './Pages/CorporateBonds';
import SovereignGoldBond from './Pages/SovereignGoldBond';
import Navbar from './Components/Navbar';
import Fotter from './Components/Fotter';
import ContactPage from './Pages/ContactPage';
import TaxFreeBond from './Pages/TaxFreeBond';
import FixedContent from './Components/FixedContent';
import InsurancePOSP from './Pages/InsurancePOSP';
import BecomeaPartner from './Pages/BecomeaPartner';
import DematPage from './Pages/DematPage';
import AgaintSharesPage from './Pages/AgaintSharesPage';
import P2Ppage from './Pages/P2Ppage';
import Insurance from './Pages/Insurance';
import CommingSoonPage from './Pages/CommingSoonPage';
import ToTop from './Components/ToTop';
import SuccessHero from './Components/SuccessHero';
import SuccessandWillPage from './Pages/SuccessandWillPage';
import ContactUsInputField from './Components/ContactUsInputField'
import BecomePartnerform from './Components/BecomePartnerform'

function Router() {
  return (
    <BrowserRouter>
      <ToTop/>
      <Navbar/>
          <Routes>
               <Route path="/" element={<HomePage/>}></Route>
              <Route path="/about-us" element={<AboutPage/>}></Route>
              <Route path="/mutual-funds" element={<MutualFunds/>}></Route>
              <Route path="/pms" element={<PortfolioPMS/>}></Route>
              <Route path="/aif" element={<AlternativeFunds/>}></Route>
              <Route path="/mld" element={<MarketLinked/>}></Route>
              <Route path="/corporate-fd" element={<CorporateFD/>}></Route>
              <Route path="/corporate-bonds" element={<CorporateBonds/>}></Route>
              <Route path="/soverign-bonds" element={<SovereignGoldBond/>}></Route>
              <Route path="/tax-free-bonds" element={<TaxFreeBond/>}></Route>
              <Route path="/contact" element={<ContactPage/>}></Route>
              <Route path="/insurance-posp" element={<InsurancePOSP/>}></Route>
              <Route path="/becomea-partner" element={<BecomeaPartner/>}></Route>
              <Route path="/demat-page" element={<DematPage/>}></Route>
              <Route path="/againt-shares" element={<AgaintSharesPage/>}></Route>
              <Route path="/p2p" element={<P2Ppage/>}></Route>
              <Route path="/insurance" element={<Insurance/>}></Route>
              <Route path="/comming-soon" element={<CommingSoonPage/>}></Route>
              <Route path="/success-will" element={<SuccessandWillPage />}></Route>
              <Route path="/contact-us-form" element={<ContactUsInputField />}/>
              <Route path="/become-a-partner-form" element={<BecomePartnerform/>}/>
          </Routes>
        <FixedContent/>  
       <Fotter/>
   </BrowserRouter>
  )
}

export default Router