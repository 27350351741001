import React from 'react'
import img_1 from '../assets/Images/SuccessWill-hero-left-img.webp'

function BenefitofWill() {
  return (
    <>
    <section className='typesofaif_bg'>
    <div className='container pt-12'>
            <h1 className='xl:text-4xl lg:text-3xl text-2xl font-bold nunito text-white text-center pb-2'>Benefits of Will & Succession Planning
</h1>
            <div className='grid md:grid-cols-4 gap-7 py-8 lg:py-16'>
                <div className='flex flex-col justify-center w-full h-48 rounded-xl px-6 bg-[#D9D9D933] text-white'>
                    <p className='xl:text-2xl text-xl font-bold nunito'>Distribution As Per Your Wishes</p>
                    <p className='text-base font-normal inter'>A Will can ensure that your assets are distributed according to your wishes</p>
                </div>
                <div className='flex flex-col justify-center w-full h-48 rounded-xl px-6 bg-[#D9D9D933] text-white'>
                    <p className=' xl:text-2xl text-xl font-bold nunito'>Avoid Family Disputes</p>
                    <p className=' text-base font-normal inter'>A Will ensures that your assets do not become a source of dispute between your  family members</p>
                </div>
                <div className='flex flex-col justify-center w-full h-48 rounded-xl px-6 bg-[#D9D9D933] text-white'>
                    <p className=' xl:text-2xl text-xl font-bold nunito'>Minimize Inconvenience to Family</p>
                    <p className=' text-base font-normal inter'>A Will significantly reduces inconvenience to your family members to get your assets</p>
                </div>
                <div className='flex flex-col justify-center w-full h-48 rounded-xl px-6 bg-[#D9D9D933] text-white'>
                    <p className=' xl:text-2xl text-xl font-bold nunito'>Business Continuity</p>
                    <p className=' text-base font-normal inter'>A proper succession plan can define roles & responsibilities of business  in future to ensure continuity & avoid disputes</p>
                </div>
            </div>
    </div>
    </section>

    <section className='lg:py-20 py-10'>
        <div className='container md:flex gap-10 xl:justify-between'>
            <div className=''>
                <img src={img_1}/>
            </div>
            <div className='flex flex-col justify-center w-full mt-8 sm:mt-0'>
                <h3 className='xl:text-4xl text-2xl font-bold nunito text-[#3C3C3C]'>Our Services in Succession and Will</h3>
                <p className='text-base font-normal inter text-[#6A6A6A] pt-4'><span className='font-bold'>1. Will Drafting and Review:</span>  Our legal experts assist you in drafting a will that accurately reflects your wishes and complies with all legal requirements.</p>
                <p className='text-base font-normal inter text-[#6A6A6A] pt-4'><span className='font-bold'>2. Estate Planning:</span>  We help you develop a detailed estate plan that includes asset distribution, tax planning, and strategies to manage and transfer your wealth efficiently.</p>
                <p className='text-base font-normal inter text-[#6A6A6A] pt-4'><span className='font-bold'>3. Trust Services:</span>  Establishing trusts can be an effective way to manage your assets during your lifetime and beyond.</p>
                <p className='text-base font-normal inter text-[#6A6A6A] pt-4'><span className='font-bold'>4. Succession Planning for Businesses:</span>  For business owners, planning for the future is crucial. We offer specialized services to ensure a smooth transition of ownership and management, safeguarding your business legacy.</p>
            </div>
        </div>
    </section>
</>
  )
}

export default BenefitofWill