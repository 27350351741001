import React from 'react'
import MutualHero from '../Components/MutualHero'
import InvesmentNeeds from '../Components/InvesmentNeeds'
import OurPartner from '../Components/OurPartner'
import FundCalculators from '../Components/FundCalculators'
import Testimonials from '../Components/Testimonials'
import MutualFundPartners from '../Components/MutualFundPartners'
import MutualFundFaq from '../Components/MutualFundFaq'

function MutualFunds() {
  return (
    <>
       
        <MutualHero/>
        <InvesmentNeeds/>
        <OurPartner/>
        <MutualFundPartners/>
        {/* <FundCalculators/> */}
        <MutualFundFaq/>
        <Testimonials/>
       
    </>
  )
}

export default MutualFunds