import React from 'react'

function HowMldWork() {
  return (
    <section className='bg-[#F5F5F5] py-12'>
        <div className='container'>
                <h1 className='xl:text-4xl text-2xl lg:text-3xl font-bold nunito text-center pb-8 sm:pb-0'>How Market-Linked Debentures work?</h1>
                <div className='grid lg:grid-cols-4 md:grid-cols-2 gap-4 md:pt-12 xl:pt-16'>
                    <div className='h-60 lg:h-72 xl:h-60 w-full bg-white rounded-xl border border-[#CDCDCD] flex justify-center items-center'>
                        <p className='inter text-sm font-normal text-[#6A6A6A] px-4 text-center'>The MLDs are linked to the performance of the Nifty 50 Index, which represents the top 50 companies listed on the National
                        Stock Exchange (NSE) in India.</p>
                    </div>
                    <div className='h-60 lg:h-72 xl:h-60 w-full bg-white rounded-xl border border-[#CDCDCD] flex justify-center items-center'>
                        <p className='inter text-sm font-normal text-[#6A6A6A] px-4 text-center'>In India, a company named ABC
                            Ltd. issues MLDS to investors, with
                            a maturity period of 5 years and a principal investment amount of 1,00,000 per MLD.</p>
                    </div>
                    <div className='h-60 lg:h-72 xl:h-60 w-full bg-white rounded-xl border border-[#CDCDCD] flex justify-center items-center'>
                        <p className='inter text-sm font-normal text-[#6A6A6A] px-4 text-center'>
                           The MLDS offer 100% principal protection, ensuring that at maturity, investors will receive back their initial investment amount of 1,00,000, irrespective of how the market performs.
                        </p>
                    </div>
                    <div className='h-60 lg:h-72 xl:h-60 w-full bg-white rounded-xl border border-[#CDCDCD] flex justify-center items-center'>
                        <p className='inter text-sm font-normal text-[#6A6A6A] px-4 text-center'>If the Nifty 50 Index performs well over the 5-year period, investors may receive additional returns on top of their principal investment. For example, if the index returns 12% annually, the investor would receive a total return of ₹1,60,090 (₹1,00,000 principal + ₹60,000 returns.)</p>
                    </div>
                </div>
        </div>
    </section>
  )
}

export default HowMldWork