import React from 'react'
import SharesHero from '../Components/SharesHero'
import BenifitOfShares from '../Components/BenifitOfShares'
import JoinUs from '../Components/JoinUs'
import FeaturedOn from '../Components/FeaturedOn'
import Testimonials from '../Components/Testimonials'
import OurProductInputForm from '../Components/OurProductInputForm'
import AgaintFaq from '../Components/AgaintFaq'


function AgaintSharesPage() {
  return (
    <>
        <SharesHero/>
        <BenifitOfShares/>
        <JoinUs/>
        <OurProductInputForm/>
        {/* <FeaturedOn/> */}
        <AgaintFaq/>
        <Testimonials/>
    </>
  )
}

export default AgaintSharesPage