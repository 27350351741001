import React from 'react'
import left from '../assets/Images/Becomepartner-right-img.webp'
import right from '../assets/Images/Becomepartner-left-img.webp'
import tick_icon from '../assets/Icons/Becomeapartner-tick-icon.svg'


function BecomeaPartnerhero() {

  return (
    <>
        <section className='becomea_partner_bg'>
            <div className='container py-14 xl:py-24 xl:mt-3'>
                <h1 className='nunito xl:text-[50px] text-2xl font-bold xl:leading-tight text-white'>Partner with India’s Leading <br/> Wealth Management Company</h1>
                    
                        <button className=' text-lg font-bold roboto bg-custom px-8 py-2 mt-6 rounded-lg text-white w-fit'>
                          <a href='#become_partner_form'>Enroll as a Partner</a>
                        </button>      
                   
            </div>
        </section>

        <section>
            <div className='container py-8 md:py-16 md:flex gap-8 xl:gap-20'>
                    <div className=''>
                        <img src={left} className=''/>
                    </div>
                    <div className='xl:w-full md:w-full flex flex-col gap-2 justify-center'>   
                        <h2 className='nunito xl:text-4xl text-xl pt-3 sm:pt-0 font-bold text-[#3C3C3C]'>Become a CheerBull Partner<br/> today.</h2>
                        <p className='inter text-base font-normal text-[#6A6A6A]'>The interest income earned from high-yield bonds is generally subject to income tax in India. The applicable tax rate depends on the investor's tax bracket. It is advised to consult with a tax advisor or refer to the relevant tax regulations to understand the specific tax implications associated with high-yield bonds in the Indian context.</p>
                    </div>
            </div>
        </section>
        {/* Benefits of cheerbull */}
        <section>
            <div className='container md:flex gap-6 pb-16'>
                    {/* Left part */}
                    <div className='flex flex-col justify-center gap-4 xl:w-8/12'>
                        <h4 className='nunito xl:text-4xl text-xl font-bold text-[#3C3C3C]'>Benefits of partnering with CheerBull</h4>
                        <div className='flex gap-3'>
                            <img src={tick_icon} className='xl:h-6 xl:w-6 h-5 w-5'/>
                            <p className='xl:text-2xl text-lg nunito font-medium -mt-1 text-[#3C3C3C]'>High Revenue Sharing</p>
                        </div>
                        <div className='flex gap-3'>
                            <img src={tick_icon} className='xl:h-6 xl:w-6 h-5 w-5'/>
                            <p className='xl:text-2xl text-lg nunito font-medium -mt-1 text-[#3C3C3C]'>Easy-to-use Partner Dashboard (NXT)</p>
                        </div>
                        <div className='flex gap-3'>
                            <img src={tick_icon} className='xl:h-6 xl:w-6 h-5 w-5'/>
                            <p className='xl:text-2xl text-lg nunito font-medium -mt-1 text-[#3C3C3C]'>Dedicated relationship manager</p>
                        </div>
                        <div className='flex gap-3'>
                            <img src={tick_icon} className='xl:h-6 xl:w-6 h-5 w-5'/>
                            <p className='xl:text-2xl text-lg nunito font-medium -mt-1 text-[#3C3C3C]'>Tech, Marketing & Branding Support</p>
                        </div>
                        <div className='flex gap-3'>
                            <img src={tick_icon} className='xl:h-6 xl:w-6 h-5 w-5'/>
                            <p className='xl:text-2xl text-lg nunito font-medium -mt-1 text-[#3C3C3C]'>Smart Training Program</p>
                        </div>
                        <button className=' text-lg font-bold roboto bg-custom px-8 py-2 mt-4 rounded-lg text-white w-fit '
                        
                        >
                          <a href='#become_partner_form'>Enroll as a Partner</a>
                      </button>
                    </div>

                    {/* Right part */}
                   <div className='md:w-1/2'>
                    <img src={right}/>
                   </div>
            </div>
        </section>

    </>
  )
}

export default BecomeaPartnerhero 