import React from 'react'
import img_1 from '../assets/Images/CEO-img-1.webp'
import img_2 from '../assets/Images/CEO-img-2.webp'

function CEOdetails() {
  return (
    <section className='typesofaif_bg'>
        <div className='container py-20 md:flex md:gap-36 justify-between'>
                {/* Left contain */}
               <div className=''>
                    <img src={img_2} className='xl:w-80 xl:h-80'/>
                    <p className='text-white nunito text-4xl font-bold pt-2'>ABHISHEK GARG</p>
                    <p className='text-white text-xl nunito font-medium'>Co-Founder</p>
                    <p className='text-white text-base inter font-normal xl:pt-6 pt-4 pb-24 sm:pb-0'>Chartered Accountant, Investment Banker, IBBI Registered Valuer, IBBI Registered Insolvency Professional.
                    Ex-VP Investment Banking/ M&A at Bank of America (DSP Merrill Lynch), Ex-Vice President. M&A/Strategy/Business Development Motherson Sumi Group. 20+ Years of Experience In Financial Services & Investments, IPO / M&A / Capital Raising / Strategy & New Business Set-up.</p>
               </div>
                {/* Right contain */}
                <div className=''>
                    <img src={img_1} className='xl:w-80 xl:h-80'/>
                    <p className='text-white nunito text-4xl font-bold pt-2'>VISHNU GARG</p>
                    <p className='text-white text-xl nunito font-medium'>Co-Founder</p>
                    <p className='text-white text-base inter font-normal xl:pt-6 pt-4 '>Chartered Accountant, IBBI Registered Insolvency Professional. Founder partner of SPARK & Associates Chartered Accountants LLP with 12 offices all over India. 20+ Years of Experience With Deep Understanding of Financial Accounting, Auditing, Taxation and advising clients on financial strategies and helping them achieve their financial goals.</p>
               </div>
        </div>
    </section>
  )
}

export default CEOdetails