import React from 'react'
import right_img from '../assets/Images/TaxFree-Bond-right-img.webp'
import { Link } from 'react-router-dom'
function TaxFreeHero() {
  return (
   <>
    <section className='taxfree_bond_bg'>
          <div className='container xl:py-16 py-10 xl:mt-3'>
                <div className='xl:w-1/2 lg:w-8/12 md:w-9/12'>
                    <h1 className='text-white nunito font-bold xl:text-5xl text-3xl pb-2'>Tax-Free Bonds</h1>
                    <p className='text-white inter font-normal xl:text-lg pb-6'>By holding tax-free bonds, you can benefit from a tax-efficient income source, as the interest earned is exempt from income tax. Additionally, tax-free bonds can contribute to potential wealth creation over the long term, as they offer fixed coupon payments and the possibility of capital appreciation. These bonds are suitable for investors who prioritize safety and seek stable returns.</p>
                    <Link to="https://clientlogin.cheerbull.com/" target="_blank">
              <button className='sign_btn px-6 py-1.5 rounded-lg text-lg font-bold roboto bg-custom text-white'>
                <span>Get Started</span>
              </button>
            </Link>
                </div>
          </div>
    </section>
     {/* Second part */}
     <section className='container md:flex gap-3 py-10 md:py-16'>
                <div className='xl:w-3/5 md:w-full flex flex-col justify-center'>
                    <h2 className='nunito text-[#3C3C3C] xl:text-[40px] text-2xl lg:text-3xl font-bold '>What are Tax-Free Bonds?</h2>
                    <p className='text-[#6A6A6A] inter font-base font-normal py-3'>Tax-free bonds are fixed-income securities issued by government entities or public sector undertakings. They provide investors with regular interest payments, which are not subject to income tax. These bonds usually have longer maturity periods and are listed on stock exchanges, allowing investors to trade them.</p>
                    <p className='text-[#6A6A6A] inter font-base font-normal '>Tax-free bonds are considered low-risk investments, backed by the creditworthiness of the issuing entity. Investors can select tax-free bonds based on factors such as coupon rates, yield to maturity, and risk appetite.</p>
                </div>
                <div className='xl:w-2/5 mt-8 sm:mt-0'>
                    <img src={right_img}/>
                </div>
            </section>
   </>
  )
}

export default TaxFreeHero