import React from 'react'
import right_img from '../assets/Images/Insurance-hero-right-img.webp'
import left_img from '../assets/Images/Insurance-hero-left-img.webp'



function InsurancePOSPHero() {
  return (
    <>
         <section className='insurance_posp_bg'>
            <div className='container xl:py-24 xl:mt-3'>
                <div className='xl:w-1/2'>
                    <h1 className='text-white nunito font-bold xl:text-5xl pb-3'>Be a POSp with Cheerbull</h1>
                    <p className='text-white inter font-normal xl:text-lg pb-4'>At Cheerbull, Point Of Sales Person (POSp) gives self-sustainability for an individual by allowing him to design own source of income and live independently being an agent.</p>
                    <button className='sign_btn px-6 py-1.5 rounded-lg text-lg font-bold roboto  bg-custom text-white'>
                            <span>Get Started</span>
                    </button>
                    </div>
                </div>
        </section>
        {/* Second part */}
        <section className='container xl:flex gap-3 py-16'>
                <div className='xl:w-3/5 flex flex-col justify-center'>
                    <h2 className='nunito text-[#3C3C3C] xl:text-[40px] font-bold '>What is POSp Person?</h2>
                    <p className='text-[#6A6A6A] inter font-base font-normal py-3'>A Point of Sales Person (POSp) is a mediator between the customer and the company. The Insurance Regulatory and Development Authority of India (IRDAI) ensures its root to diversify the growth of all insurance-related activities, hence POSp.</p>
                    <p className='text-[#6A6A6A] inter font-base font-normal '>A POSp who wish to dive in the depts of being an advisor or an agent is most greeted and is permitted to go ahead and expand their level of growth by introducing insurance policies to the customers.</p>
                </div>
                <div className='xl:w-2/5'>
                    <img src={right_img}/>
                </div>
            </section>

             {/* third part */}
    <section className='container xl:flex gap-5 pb-20 pt-4'>
        <div className='xl:w-2/5'>
            <img src={left_img}/>
        </div>
        <div className='xl:w-3/5 flex flex-col justify-center'>
            <h2 className='nunito text-[#3C3C3C] xl:text-4xl font-bold '>Eligibility criteria to become a POSp for Cheerbull</h2>
            <p className='text-[#6A6A6A] inter font-base font-normal py-2'>If you have to urge to be a POSp you need to qualify the criteria set by IRDAI regulations to appoint an Insurance Agent.</p>
            <ul className='list-disc nunito text-xl font-medium text-[#3C3C3C] pl-5 py-3'>
                <li>Your maturity and thinking level should be that of 18 years and above.</li>
                <li className='mt-3'>Must complete your basic high school.</li>
                <li className='mt-3'>You need to have documents like PAN card, proof of identification and address, recent photographs and essential exam certificate.</li>
                <li className='mt-3'>Attend a verified training to be a POSp, organized by the company and pass the test conducted by IRDAI guidelines.</li>
                <li className='mt-3'>Own an approved certification by IRDAI post-training.</li>
            </ul>
        </div>
    </section>  
                {/* Refer and Become a Sub-broker */}
            <section>
                <div className='container grid xl:grid-cols-2 gap-8 pb-16'>
                        <div className='xl:h-32 w-full bg-[#014579] flex justify-center items-center rounded-xl'>
                            <p className='nunito xl:text-4xl font-bold text-white'>Refer Leads & earn Referral Fees</p>
                        </div>
                        <div className='xl:h-32 w-full bg-custom flex justify-center items-center rounded-xl'>
                            <p className='nunito xl:text-4xl font-bold text-white'>Become a Sub-Broker</p>
                        </div>
                </div>
            </section>    
    </>
  )
}

export default InsurancePOSPHero