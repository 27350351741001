 import React, { useState, useRef, useEffect } from 'react';

 const Accordion = ({ question, answer, isOpen, onClick }) => {
   const [height, setHeight] = useState("0px");
   const content = useRef(null);
 
   useEffect(() => {
     setHeight(isOpen ? `${content.current.scrollHeight}px` : "0px");
   }, [isOpen]);
 
   return (
     <div className={`border-b rounded-lg border-[#C3C3C3] w-full ${isOpen ? 'bg-white' : ''}`}>
       <button
         className="flex justify-between w-full py-4 lg:py-8 px-4 lg:px-8 text-left focus:outline-none"
         onClick={onClick}
       >
         <span className="xl:text-2xl text-lg text-[#3C3C3C] nunito font-bold">{question}</span>
         <svg
           className={`w-6 h-6 transform transition-transform duration-300 ${isOpen ? 'rotate-180' : 'rotate-0'}`}
           fill="none"
           stroke="currentColor"
           viewBox="0 0 24 24"
           xmlns="http://www.w3.org/2000/svg"
         >
           <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"></path>
         </svg>
       </button>
       <div
         ref={content}
         style={{ maxHeight: `${height}` }}
         className="overflow-hidden transition-max-height duration-300 ease-in-out"
       >
         <div className="py-4 lg:py-6 px-4 lg:px-8 text-base inter text-[#6A6A6A]">{answer}</div>
       </div>
     </div>
   );
 };
 
 const  MldFaq = () => {
   const [openIndex, setOpenIndex] = useState(null);
 
   const faqs = [
     {
       question: " How do MLDs work?",
       answer: " MLDs provide returns based on the performance of underlying market indices. They typically offer capital protection but have varying returns depending on the linked index’s performance.",
     },
     {
       question: " How do MLDs differ from regular bonds? ",
       answer: "Unlike regular bonds with fixed interest rates, MLD returns are tied to market performance, potentially offering higher returns but also carrying higher risk.",
     },
     {
       question: " What are the risks associated with MLDs?",
       answer: "Risks include market risk, where returns are dependent on index performance, and liquidity risk, as MLDs may not be easily tradable in the secondary market.",
     },
     {
       question: "Are MLDs suitable for all investors?",
       answer: "MLDs are generally suitable for investors looking for fixed-income securities with higher return potential linked to market indices. They may not be ideal for risk-averse investors.",
     },
     {
       question: "How do I invest in MLDs?",
       answer: " Investing in MLDs involves purchasing them through financial institutions or brokers. Ensure you understand the terms and underlying indices before investing.",
     },
     // Add more FAQs as needed
   ];
 
   const handleToggle = index => {
     setOpenIndex(openIndex === index ? null : index);
   };
 
   return (
     <section className='bg-[#F5F5F5]'>
         <div className='container lg:py-16 py-8'>
             <div className="">
             <h2 className="xl:text-4xl text-2xl lg:text-3xl text-[#3C3C3C] nunito font-bold text-center pb-6">Frequently Asked Questions</h2>
             {faqs.map((faq, index) => (
                 <Accordion
                   key={index}
                   question={faq.question}
                   answer={faq.answer}
                   isOpen={openIndex === index}
                   onClick={() => handleToggle(index)}
                 />
             ))}
             </div>
         </div>
     </section>
   );
 };
 
 export default  MldFaq;
 