import React from 'react'
import Navbar from '../Components/Navbar'
import PortfolioHero from '../Components/PortfolioHero'
import InvestmentPlatformfor from '../Components/InvestmentPlatformfor'
import CompanyIcons from '../Components/CompanyIcons'
import FeaturedOn from '../Components/FeaturedOn'
import FaQ from '../Components/FaQ'
import Testimonials from '../Components/Testimonials'
import FeaturedOnTwo from '../Components/FeaturedOnTwo'
import OurProductInputForm from '../Components/OurProductInputForm'
import BenefitsofPms from '../Components/BenefitsofPms'

function PortfolioPMS() {
  return (
   <>
    
    <PortfolioHero/>
    {/* <InvestmentPlatformfor/> */}
    {/* <CompanyIcons/> */}
    <BenefitsofPms/>
    {/* <OurProductInputForm/> */}
    {/* <FeaturedOnTwo/> */}
    {/* <FaQ/> */}
    <Testimonials/>
    
   </>
  )
}

export default PortfolioPMS