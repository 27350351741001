import React, { useState, useRef, useEffect } from 'react';

const Accordion = ({ question, answer, isOpen, onClick }) => {
  const [height, setHeight] = useState("0px");
  const content = useRef(null);

  useEffect(() => {
    setHeight(isOpen ? `${content.current.scrollHeight}px` : "0px");
  }, [isOpen]);

  return (
    <div className={`border-b rounded-lg border-[#C3C3C3] w-full ${isOpen ? 'bg-white' : ''}`}>
      <button
        className="flex justify-between w-full py-4 lg:py-8 px-4 lg:px-8 text-left focus:outline-none"
        onClick={onClick}
      >
        <span className="lg:text-2xl text-lg text-[#3C3C3C] nunito font-bold">{question}</span>
        <svg
          className={`w-6 h-6 transform transition-transform duration-300 ${isOpen ? 'rotate-180' : 'rotate-0'}`}
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"></path>
        </svg>
      </button>
      <div
        ref={content}
        style={{ maxHeight: `${height}` }}
        className="overflow-hidden transition-max-height duration-300 ease-in-out"
      >
        <div className="py-4 lg:py-6 px-4 lg:px-8 text-base inter text-[#6A6A6A]">{answer}</div>
      </div>
    </div>
  );
};

const  TaxBondFaq = () => {
  const [openIndex, setOpenIndex] = useState(null);

  const faqs = [
    {
      question: "How are Tax-Free Bonds different from other fixed income investments?",
      answer: "Compared to fixed deposits or regular bonds, these Tax-Free Bonds are more attractive since there is no tax on interest income. But they also come with a longer lock-in period and probably lower interest rates than some other fixed income instruments.",
    },
    {
      question: "Is there tax benefit on principal repayment of Tax-Free Bonds?",
      answer: "While the interest income from Tax-Free Bonds is tax free, the principal amount at maturity is not tax free. The main advantage is the tax free interest earned during the investment period.",
    },
    {
      question: "Can Tax-Free Bonds be transferred to another person?",
      answer: "Yes, Tax-Free Bonds can be transferred to another person. Fill up the transfer form and comply with the issuer’s guidelines. But check the specific terms and conditions on transferability of the bonds.",
    },
    {
      question: "Can Tax-Free Bonds be included in a systematic investment plan (SIP)?",
      answer: "Tax-Free Bonds are not available for SIP due to their fixed investment and long tenure. But you can plan lump sum investment in Tax-Free Bonds and include it in your overall investment strategy.",
    },
    {
      question: "How do I invest in Tax-Free Bonds through Cheerbull?",
      answer: "Cheerbull provides a hassle free process for investing in Tax-Free Bonds. Our experts will help you choose the right bonds based on your investment goals and risk profile. We will take care of the investment and you will get the benefit of tax free interest income.",
    },
    // Add more FAQs as needed
  ];

  const handleToggle = index => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <section className='bg-[#F5F5F5]'>
        <div className='container lg:py-16 py-8'>
            <div className="">
            <h2 className="xl:text-4xl text-2xl text-[#3C3C3C] nunito font-bold text-center pb-6">Frequently Asked Questions</h2>
            {faqs.map((faq, index) => (
                <Accordion
                  key={index}
                  question={faq.question}
                  answer={faq.answer}
                  isOpen={openIndex === index}
                  onClick={() => handleToggle(index)}
                />
            ))}
            </div>
        </div>
    </section>
  );
};

export default  TaxBondFaq;
