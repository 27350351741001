import React from 'react'
import InsurancePOSPHero from '../Components/InsurancePOSPHero'
import SoldByPOSp from '../Components/SoldByPOSp'
import BenefitsofPOSP from '../Components/BenefitsofPOSP'
import WhyisPOSp from '../Components/WhyisPOSp'
import JoinUs from '../Components/JoinUs'
import FeaturedOnTwo from '../Components/FeaturedOnTwo'
import FaQ from '../Components/FaQ'
import Testimonials from '../Components/Testimonials'

function InsurancePOSP() {
  return (
    <>
        <InsurancePOSPHero/>
        <SoldByPOSp/>
        <BenefitsofPOSP/>
        <WhyisPOSp/>
        <JoinUs/>
        <FeaturedOnTwo/>
        <FaQ/>
        <Testimonials/>
    </>
  )
}

export default InsurancePOSP