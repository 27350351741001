import React from 'react'
import icon_1 from '../assets/Icons/Mid-Cap-Funds-icon.svg'
import icon_2 from '../assets/Icons/Index-Funds-icon.svg'
import icon_3 from '../assets/Icons/Arbitrage-Funds-icon.svg'
import icon_4 from '../assets/Icons/Multi-Cap-Funds-icon.svg'
import icon_5 from '../assets/Icons/Small-Cap-Funds-icon.svg'
import icon_6 from '../assets/Icons/ELSS-Funds-icon.svg'

function InvesmentNeeds() {
  return (
    <section className='bg-[#F5F5F5]'>
        <div className='container py-10 lg:py-20'>
                <h1 className='text-center text-2xl xl:text-4xl text-[#323232] font-bold nunito'>Find the Perfect Mutual Funds for <br/>Your Investment Needs</h1>
               <div className='flex justify-center'>
                    <div className='lg:pt-16 pt-10 grid lg:grid-cols-4 sm:grid-cols-2 gap-12 '>
                        <div className='lg:w-56 lg:h-72 hover:bg-white cursor-pointer rounded-xl flex flex-col items-center  py-3 px-4'>
                            <img src={icon_1} className='w-14 h-14'/>
                            <h2 className='text-xl font-semibold text-[#323232] plus-jakarta-sans py-2'>Mid Cap Funds</h2>
                            <p className='inter text-sm font-normal text-center'>Invest in mid-cap mutual funds which invest in companies with a market capitalization between Rs. 500 crores to Rs. 10,000 crores and provide a higher potential for growth.</p>
                        </div>
                        <div className='lg:w-56 lg:h-72 hover:bg-white cursor-pointer rounded-xl flex flex-col items-center  py-3 px-4'>
                            <img src={icon_2} className='w-14 h-14'/>
                            <h2 className='text-xl font-semibold text-[#323232] plus-jakarta-sans py-2'>Index Funds</h2>
                            <p className='inter text-sm font-normal text-center'>Index funds are a type of mutual fund that aim to replicate the performance of a specific index in the stock market. In India, these indices often include the Nifty 50, Sensex, Nifty Next 50, and others.</p>
                        </div>
                        <div className='lg:w-56 lg:h-72 hover:bg-white cursor-pointer rounded-xl flex flex-col items-center  py-3 px-4 lg:mt-4'>
                            <img src={icon_3} className='w-10 h-10'/>
                            <h2 className='text-xl font-semibold text-[#323232] plus-jakarta-sans py-2'>Large Cap Funds</h2>
                            <p className='inter text-sm font-normal text-center'>Invest in large-cap mutual funds which invest in well-established companies with high market capitalization, providing stability and lower risk investment opportunities.</p>
                        </div>
                        <div className='lg:w-56 lg:h-72 hover:bg-white cursor-pointer rounded-xl flex flex-col items-center  py-3 px-4'>
                            <img src={icon_4} className='w-14 h-14'/>
                            <h2 className='text-xl font-semibold text-[#323232] plus-jakarta-sans py-2'>Multi Cap Funds</h2>
                            <p className='inter text-sm font-normal text-center'>These mutual funds invest in stocks across various market capitalizations, providing greater diversification and potential for higher returns.</p>
                        </div>
                    </div>
               </div>
                  <div className='sm:flex justify-center gap-14 mt-12'>
                        <div className='lg:w-56 lg:h-72 hover:bg-white cursor-pointer rounded-xl flex flex-col items-center  py-3 px-4'>
                            <img src={icon_5} className='w-14 h-14'/>
                            <h2 className='text-xl font-semibold text-[#323232] plus-jakarta-sans py-2'>Small Cap Funds</h2>
                            <p className='inter text-sm font-normal text-center'>Invest in small-cap mutual funds which invest in companies with market capitalization below Rs. 500 crores and can potentially provide higher returns but come with higher risks.</p>
                        </div>
                        <div className='lg:w-56 lg:h-72 hover:bg-white cursor-pointer rounded-xl flex flex-col items-center  py-3 px-4 mt-12 sm:mt-0'>
                            <img src={icon_6} className='w-14 h-14'/>
                            <h2 className='text-xl font-semibold text-[#323232] plus-jakarta-sans py-2'>Tax-saving options</h2>
                            <p className='inter text-sm font-normal text-center'>Invest in tax-saving mutual funds, also known as ELSS, and claim tax deductions up to Rs. 1.5 lakh under section 80C.</p>
                        </div>
                  </div>
        </div>
    </section>
  )
}

export default InvesmentNeeds