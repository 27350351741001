import React from 'react'
import left_img from '../assets/Images/Tax-img.webp'
import right_img from '../assets/Images/Implimantion-second-img.webp'
function ImplicationsofSGB() {
  return (
    <>
        <section>
            <div className='container pt-10 xl:pt-20 md:flex md:gap-10 xl:gap-20'>
                    {/* Left side */}
                    <div className=''>
                        <img src={left_img}/>
                    </div>
                    {/* Right side */}
                    <div className='xl:w-9/12 flex flex-col justify-center'>
                        <h1 className='text-[#3C3C3C] nunito xl:text-4xl text-2xl lg:text-3xl font-bold py-4'>Tax implications of<br/> SGBs</h1>
                        <p className='text-[#6A6A6A] inter font-normal text-base'>Interest earned on SGBs is taxable as per the income tax slab of the investor. However, capital gains arising at maturity are exempt from capital gains tax. In case of a premature exit from the investment, capital gains tax may be applicable as per prevailing tax regulations.</p>
                    </div>
            </div>
        </section>

        <section>
            <div className='container xl:pb-20 pb-10 md:flex gap-14 md:pt-10 xl:pt-0'>
                {/* Left side */}
             <div className='flex flex-col justify-center '>
                <h1 className='nunito xl:text-[40px] text-2xl lg:text-3xl font-bold text-[#3C3C3C] pt-6 sm:pt-0 pb-6 xl:leading-tight'>Risks associated with<br/> SGBs</h1>
                <p className='nunito text-lg xl:text-2xl font-bold text-[#3C3C3C]'>Market Risk</p>
                <p className='inter text-base font-normal text-[#6A6A6A] pb-8'>The value of SGBs can fluctuate based on the prevailing market price of gold, which may affect the returns at the time of redemption/sale.</p>
                <p className='nunito text-lg xl:text-2xl font-bold text-[#3C3C3C]'>Liquidity Risk</p>
                <p className='inter text-base font-normal text-[#6A6A6A] pb-8'>SGBs have a limited secondary market, making it challenging to sell them before maturity.</p>
                <p className='nunito text-lg xl:text-2xl font-bold text-[#3C3C3C]'>Sovereign Risk</p>
                <p className='inter text-base font-normal text-[#6A6A6A] pb-8'>While SGBs are backed by the Government of India, there is still a theoretical risk of default, although highly unlikely.</p>
              </div>
              {/* Right side */}
              <div className='xl:w-8/12'>
                <img src={right_img}/>
              </div>
            </div>
        </section>
    </>
  )
}

export default ImplicationsofSGB