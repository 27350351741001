import React from 'react'
import right_img from '../assets/Images/MLD-hero-second-part.webp'
import { Link } from 'react-router-dom'
function MarketLinkedHero() {
  return (
    <>  
    {/* First part */}
    <section className='MldsHero_bg'>
        <div className='container py-10 xl:py-24 xl:mt-3 '>
        <div className='lg:w-1/2 md:w-9/12'>
            <h1 className='text-white nunito font-bold text-2xl lg:text-3xl xl:text-5xl pb-3'>Market-Linked Debentures (MLDs)</h1>
            <p className='text-white inter font-normal text-base xl:text-lg pb-4'>Diversify your investment portfolio by considering Market-Linked Debentures (MLDs), which blend the features of fixed-income instruments with the opportunity for increased returns tied to equity market performance.</p>
            <Link to="https://clientlogin.cheerbull.com/" target="_blank">
              <button className='sign_btn px-6 py-1.5 rounded-lg text-lg font-bold roboto bg-custom text-white'>
                <span>Get Started</span>
              </button>
            </Link>

        </div>
        </div>
    </section>
    {/* Second part */}
    <section className='container md:flex gap-3 py-8 xl:py-16'>
        <div className='xl:w-3/5 flex flex-col justify-center'>
            <h2 className='nunito text-[#3C3C3C] xl:text-4xl lg:text-3xl text-2xl font-bold '>What are Market-Linked<br/> Debentures?</h2>
            <p className='text-[#6A6A6A] inter font-base font-normal py-3'>Market-Linked Debentures (MLDs) are hybrid investment options issued in the form of bonds.</p>
            <p className='text-[#6A6A6A] inter font-base font-normal '>The returns or interest received on MLDs are dependent on the performance of the underlying market, such as the Nifty 50 Index or Nifty 100 Index.</p>
            <ul className='list-disc nunito xk:text-2xl text-lg font-semibold text-[#3C3C3C] pl-5 py-8'>
                <li>Higher potential returns</li>
                <li>Flexibility and customization</li>
                <li>Diversification and risk management</li>
            </ul>
        </div>
        <div className='xl:w-2/6'>
            <img src={right_img}/>
        </div>
    </section>
</>
  )
}

export default MarketLinkedHero