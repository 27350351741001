import React from 'react'
import left_img from '../assets/Images/Tax-img.webp'
import right_img from '../assets/Images/Implimantion-second-img.webp'

function ImplicationsofTaxBonds() {
  return (
    <>
      <section>
            <div className='container py-10 xl:pt-20 md:flex gap-4 xl:gap-20'>
                    {/* Left side */}
                    <div className=''>
                        <img src={left_img}/>
                    </div>
                    {/* Right side */}
                    <div className='xl:w-9/12 md:w-full flex flex-col justify-center'>
                        <h1 className='text-[#3C3C3C] nunito xl:text-4xl text-2xl lg:text-3xl font-bold py-4'>Tax implications of Tax-Free Bonds</h1>
                        <p className='text-[#6A6A6A] inter font-normal text-base'> The interest income earned from tax-free bonds is exempt from income tax without any limit or ceiling. However, it is crucial to consider that any capital gains generated from selling tax-free bonds before maturity may be subject to taxation. It is advisable to consult with a tax advisor or refer to the latest income tax rules to understand the specific tax implications associated with tax-free bonds.</p>
                    </div>
            </div>
        </section>

        <section>
            <div className='container xl:py-20 md:py-6 md:flex gap-14'>
                {/* Left side */}
             <div className='flex flex-col justify-center '>
                <h1 className='nunito xl:text-[40px] text-2xl lg:text-3xl font-bold text-[#3C3C3C] pb-10'>Risks associated with Tax-Free Bonds</h1>
                <p className='nunito text-lg md:text-xl xl:text-2xl font-bold text-[#3C3C3C]'>Interest rate risk</p>
                <p className='inter text-base font-normal text-[#6A6A6A] pb-8'>If interest rates rise after purchasing tax-free bonds, their market value may decline, affecting liquidity and potential capital gains.</p>
                <p className='nunito text-lg md:text-xl xl:text-2xl font-bold text-[#3C3C3C]'>Inflation risk</p>
                <p className='inter text-base font-normal text-[#6A6A6A] pb-8'>Tax-free bonds may not provide protection against inflation, as the fixed interest payments may not keep pace with rising prices, impacting the real value of returns.</p>
                <p className='nunito text-lg md:text-xl xl:text-2xl font-bold text-[#3C3C3C]'>Credit risk</p>
                <p className='inter text-base font-normal text-[#6A6A6A] pb-8'>While SGBs are backed by the Government of India, there is still a theoretical risk of default, although highly unlikely.</p>
                <p className='nunito text-lg md:text-xl xl:text-2xl font-bold text-[#3C3C3C]'>Liquidity risk</p>
                <p className='inter text-base font-normal text-[#6A6A6A] pb-8'>Tax-free bonds may have limited liquidity in the secondary market, making it difficult to sell them before maturity, potentially restricting exit options for investors.</p>
              </div>
              {/* Right side */}
              <div className='xl:w-8/12 mb-10 sm:mb-0'>
                <img src={right_img}/>
              </div>
            </div>
        </section>
    </>
  )
}

export default ImplicationsofTaxBonds