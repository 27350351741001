import React, { useState, useEffect } from 'react';
import logo from '../assets/Icons/logo-for-fixed-row.svg';
import { Link } from 'react-router-dom';
function FixedContent() {
  const [show, setShow] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setShow(true);
      } else {
        setShow(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <section
      className={`bg-[#16362e] border-t border-white fixed bottom-0 z-50 w-full transition-all duration-300 ease-in-out transform ${
        show ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-4'
      }`}
    >
      <div className='container flex md:justify-between justify-center gap-2 xl:py-3 py-2'>
        <div className='flex xl:gap-6 gap-2'>
            <div className='mt-2 sm:mt-0'>
              <img src={logo} className='w-24'/>
            </div>
            <div>
              <h1 className='text-[#ffffff] nunito text-xs md:text-lg xl:text-3xl font-bold mt-1 xl:mt-2'>
                With CHEERBULL , begin your investment journey right now.
              </h1>
            </div>
        </div>
        <div className='md:pt-2'>
        <Link to="https://clientlogin.cheerbull.com/" target="_blank">
          <button className='lg:text-lg text-xs font-bold roboto bg-custom lg:px-8 px-4  py-1 rounded-lg text-white'>
            <span>Get Started</span>
          </button>
        </Link>
        </div>
      </div>
    </section>
  );
}

export default FixedContent;
