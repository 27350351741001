import React, { useState, useEffect, useRef } from "react";
import { Link, NavLink , useLocation } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import logo from "../assets/Icons/cheerbull-logo.png";
import logo_2 from '../assets/Icons/logo-for-mobile.png';
import play_store from "../assets/Icons/Google-play.svg";
import app_store from "../assets/Icons/ios-app-icon.svg";
import { FaBars, FaTimes } from 'react-icons/fa';
import apple_store from "../assets/Icons/app-store.svg";
import google_play_store from "../assets/Icons/Navbar-google-app.svg";
import phone from "../assets/Icons/phone-icon.svg";
import call_back from "../assets/Icons/call-back-icon.svg";
import dropdown_icon from '../assets/Icons/Our-product-dropdown-icon.svg'
import live_chat from '../assets/Icons/Live-chat.svg'


function Navbar() {
  const [isOpen, setIsOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);
  const location = useLocation();

  const navigate = useNavigate();

  const handleClick = () => {
    navigate('contact-us-form'); 
  };
  
  const handleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdownOpen(false);
    }
  };

  useEffect(() => {
    if (dropdownOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownOpen]);
  
  useEffect(() => {
    setIsOpen(false);
  }, [location]);

  return (
    <section className="">
      <div className="hidden lg:block container">
        <div className="grid grid-rows-2 grid-flow-col gap-2 ">
          <div className="row-span-3 py-2 mt-3">
            <Link to={'/'}>
              <img src={logo} className="h-20"/>
            </Link>
          </div>
          <div className="col-span-2 bg-[#EDEDED] flex justify-between rounded-b">
            <div className="py-2 flex gap-2 pl-3">
              {/* <div className="flex gap-2 xl:w-36 border-r border-customGray px-3">
                <img src={phone} className="h-5 w-8 mt-1" />
                <p className="text-sm font-bold roboto mt-1">1800 2666</p>
              </div> */}
              <div className="flex gap-2 xl:w-32 border-r border-customGray cursor-pointer px-3"
               onClick={handleClick}>
                <img src={call_back} className="h-3 w-3 mt-2" />
                <p className="lg:text-xs xl:text-sm font-bold roboto mt-1"> Call Back</p>
              </div>
              {/* <img src={live_chat} /> */}
            </div>
            <div className="flex lg:gap-4 xl:gap-7">
              <Link to="https://clientlogin.cheerbull.com/" target="_blank">
              <p  className="px-4 py-2 rounded-md mt-1.5 asap lg:text-xs xl:text-sm font-bold text-white bg-[#014579]">
              Login / Register 
              </p>
              </Link>
              <Link to="https://partnerlogin.cheerbull.com/" target="_blank">
                <button className="text-custom lg:text-xs xl:text-sm font-bold border-2 border-custom rounded-lg px-3 py-1.5 my-1.5 asap">Login as Partner</button>
              </Link>
              <Link to="becomea-partner" className="px-3 my-1.5 pt-2 mr-4 asap lg:text-xs xl:text-sm font-bold rounded-lg text-white bg-custom">
                <span>Become a Partner</span>
              </Link>
            </div>
          </div>
          <div className="row-span-2 col-span-2 flex justify-between">
            <div className="flex lg:gap-4 xl:gap-5 inter lg:text-xs xl:text-sm font-semibold items-center">
              <NavLink to="/"> Home </NavLink>
              <NavLink to="/about-us"> About Us </NavLink>
              <div className="relative flex items-center cursor-pointer" onClick={handleDropdown} ref={dropdownRef}>
                <p className="hover:text-custom">Our Products</p>
                <img src={dropdown_icon} className={`ml-1 h-2.5 w-2.5 transform transition-transform ${dropdownOpen ? 'rotate-180' : 'rotate-0'}`} alt="Dropdown Icon" />
                {dropdownOpen && (
                  <div className="absolute top-full mt-4 bg-white border rounded shadow-lg w-80 z-50">
                    <div className="flex flex-col">
                      <NavLink to="/pms" className="pl-7 pt-5 pb-3 inter text-sm font-semibold hover:text-custom hover:underline cursor-pointer">Portfolio Management Services (PMS)</NavLink>
                      <NavLink to="/aif" className="pl-7 py-3 inter text-sm font-semibold hover:text-custom hover:underline cursor-pointer">Alternative Investment Funds (AIF)</NavLink>
                      <NavLink to="/mld" className="pl-7 py-3 inter text-sm font-semibold hover:text-custom hover:underline cursor-pointer">Market-Linked Debentures (MLDs)</NavLink>
                      <NavLink to="/corporate-fd" className="pl-7 py-3 inter text-sm font-semibold hover:text-custom hover:underline cursor-pointer">Fixed Deposit</NavLink>
                      <NavLink to="/corporate-bonds" className="pl-7 py-3 inter text-sm font-semibold hover:text-custom hover:underline cursor-pointer">Corporate Bonds</NavLink>
                      <NavLink to="/soverign-bonds" className="pl-7 py-3 inter text-sm font-semibold hover:text-custom hover:underline cursor-pointer">Sovereign Gold Bonds (SGB)</NavLink>
                      <NavLink to="/tax-free-bonds" className="pl-7 py-3 inter text-sm font-semibold hover:text-custom hover:underline cursor-pointer">Tax Free Bonds</NavLink>
                      <NavLink to="/demat-page" className="pl-7 py-3 inter text-sm font-semibold hover:text-custom hover:underline cursor-pointer">Equity/Demat</NavLink>
                      <NavLink to="/againt-shares" className="pl-7 py-3 inter text-sm font-semibold hover:text-custom hover:underline cursor-pointer">Loan against MF/Shares</NavLink>
                      <NavLink to="/p2p" className="pl-7 pt-3 pb-6 inter text-sm font-semibold hover:text-custom hover:underline cursor-pointer">Peer-to-Peer Lending (P2P)</NavLink>
                      {/* <NavLink to="/contact" className="pl-7 pt-3 pb-5 inter text-sm font-semibold hover:text-custom hover:underline cursor-pointer">Contact Us</NavLink> */}
                    </div>
                  </div>
                )}
              </div>
              <NavLink to="/mutual-funds"> Mutual Funds/SIP </NavLink>
              <NavLink to="/insurance"> Insurance </NavLink>
              <NavLink to="/success-will"> Succession & Will </NavLink>
              <Link to="https://clientlogin.cheerbull.com/" target="_blank">Track Portfolio Free!!</Link>
              <NavLink to="/comming-soon"> Goal Calculators </NavLink>
            </div>
            <div className="flex gap-2 items-center">
              <p className="text-[#181818] asap lg:text-xs xl:text-sm font-bold">Download mobile app -</p>
              <Link to="https://play.google.com/store/apps/details?id=com.cheerbull" target="_blank">
                <img src={google_play_store} className="h-6 w-6" />
              </Link>
              <Link to="https://apps.apple.com/in/app/cheerbull/id6651834436" target="_blank">
                <img src={apple_store} className="h-6 w-6" />
              </Link>
            </div>
          </div>
        </div>
      </div>

      {/* Mobile Navbar */}
      <div className="block lg:hidden">
        <div className="bg-white shadow-md">
          <div className="container mx-auto px-2 py-3 flex items-center justify-between">
            <div className="flex items-center">
            <Link to={'/'}>
              <img src={logo_2} className="w-36"/>
            </Link>
            </div>
            <div className="lg:hidden">
              <button onClick={() => setIsOpen(!isOpen)}>
                {isOpen ? <FaTimes className="h-6 w-6" /> : <FaBars className="h-6 w-6" />}
              </button>
            </div>
          </div>
          {isOpen && (
            <div className=" bg-white px-4">
              <ul className="flex flex-col nunito text-lg font-bold text-[#3C3C3C]">
                <NavLink to="/" className="py-3 border-b border-[#E4E4E4]"><a href="/">Home</a></NavLink>
                <NavLink to="/about-us" className="py-3 border-b border-[#E4E4E4]"><a href="#">About Us</a></NavLink>
                <div className="relative flex items-center cursor-pointer" onClick={handleDropdown} ref={dropdownRef}>
                  <p className="hover:text-custom py-3 border-b border-[#E4E4E4]">Our Products</p>
                  <img src={dropdown_icon} className={`ml-1 h-2.5 w-2.5 transform transition-transform ${dropdownOpen ? 'rotate-180' : 'rotate-0'}`} alt="Dropdown Icon" />
                  {dropdownOpen && (
                    <div className="absolute top-full mt-4 bg-white border rounded shadow-lg w-80 z-50">
                      <div className="flex flex-col">
                        <NavLink to="/pms" className="pl-7 py-3 inter text-sm font-semibold hover:text-custom hover:underline cursor-pointer">Portfolio Management Services (PMS)</NavLink>
                        <NavLink to="/aif" className="pl-7 py-3 inter text-sm font-semibold hover:text-custom hover:underline cursor-pointer">Alternative Investment Funds (AIF)</NavLink>
                        <NavLink to="/mld" className="pl-7 py-3 inter text-sm font-semibold hover:text-custom hover:underline cursor-pointer">Market-Linked Debentures (MLDs)</NavLink>
                        <NavLink to="/corporate-fd" className="pl-7 py-3 inter text-sm font-semibold hover:text-custom hover:underline cursor-pointer">Corporate FDs</NavLink>
                        <NavLink to="/corporate-bonds" className="pl-7 py-3 inter text-sm font-semibold hover:text-custom hover:underline cursor-pointer">Corporate Bonds</NavLink>
                        <NavLink to="/soverign-bonds" className="pl-7 py-3 inter text-sm font-semibold hover:text-custom hover:underline cursor-pointer">Sovereign Gold Bonds (SGB)</NavLink>
                        <NavLink to="/tax-free-bonds" className="pl-7 py-3 inter text-sm font-semibold hover:text-custom hover:underline cursor-pointer">Tax Free Bonds</NavLink>
                        <NavLink to="/demat-page" className="pl-7 py-3 inter text-sm font-semibold hover:text-custom hover:underline cursor-pointer">Equity/Demat</NavLink>
                        <NavLink to="/againt-shares" className="pl-7 py-3 inter text-sm font-semibold hover:text-custom hover:underline cursor-pointer">Loan against MF/Shares</NavLink>
                        <NavLink to="/contact" className="pl-7 pt-3 pb-5 inter text-sm font-semibold hover:text-custom hover:underline cursor-pointer">Contact Us</NavLink>
                      </div>
                    </div>
                  )}
                </div>
                <NavLink to="/mutual-funds" className="py-3 border-b border-[#E4E4E4]"><a href="#">Mutual Funds/SIP</a></NavLink>
                <NavLink to="/insurance" className="py-3 border-b border-[#E4E4E4]"><a href="#">Insurance</a></NavLink>
                <NavLink to="/success-will" className="py-3 border-b border-[#E4E4E4]"><a href="#">Succession & Will</a></NavLink>
                <Link to="https://clientlogin.cheerbull.com/" target="_blank" className="py-3 border-b border-[#E4E4E4]">Track Portfolio Free!!</Link>
                <NavLink to="/comming-soon" className="py-3 border-b border-[#E4E4E4]"><a href="#">Goal Calculators</a></NavLink>
              </ul>
              <div className="flex gap-2 pt-6">
                <Link to="becomea-partner" className="bg-[#014579] text-white py-3 px-4 font-bold text-sm asap">Become a Partner</Link>
                <Link to="https://partnerlogin.cheerbull.com/" target="_blank">
                <button className="text-custom lg:text-xs xl:text-sm font-bold border-2 border-custom rounded-lg  asap">Login as Partner</button>
                </Link>
                <Link to="https://clientlogin.cheerbull.com/" target="_blank">
                <button className="bg-custom text-white py-3 px-4 rounded-md font-bold text-sm asap">Login/Register</button>
                </Link>
              </div>
              <p className="text-[#181818] asap text-lg pt-4 pb-4 font-bold">Download mobile app -</p>
              <div className="flex gap-6 pb-6">
              <Link to="https://play.google.com/store/apps/details?id=com.cheerbull" target="_blank">
                <div className="border shadow-md rounded-lg">
                  <img src={play_store} alt="App Store" className="h-8" />
                </div>
              </Link>
              <Link to="https://apps.apple.com/in/app/cheerbull/id6651834436" target="_blank">
                <div className="border shadow-md rounded-lg">
                  <img src={app_store} alt="Google Play" className="h-8" />
                </div>
              </Link>
              </div>
              <div className="grid grid-cols-2 pb-8">
                {/* <div className="flex gap-3 border-r-2 w-full border-customGray pr-2">
                  <img src={phone} className="h-9 w-9" />
                  <p className="text-[#282828] roboto text-sm font-bold mt-2">1800 2666</p>
                </div> */}
                <div className="flex gap-3 border-r-2 w-full pl-6 border-customGray pr-2" onClick={handleClick}>
                  <img src={call_back} className="h-4 w-4 mt-2" />
                  <p className="text-[#282828] roboto text-sm font-bold mt-2"> Call Back</p>
                </div>
                {/* <div className="mt-3">
                  <img src={live_chat} />
                </div> */}
              </div>
            </div>
          )}
        </div>
      </div>
    </section>
  );
}

export default Navbar;
