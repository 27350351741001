import React, { useState, useRef } from 'react';
import emailjs from '@emailjs/browser';
import mail_icon from '../assets/Icons/email_icon.svg';
import name_icon from '../assets/Icons/name_icon.svg';
import phone from '../assets/Icons/telephone_icon.svg';
import referral from '../assets/Icons/referral_icon.svg';
import { Link } from 'react-router-dom';

function OurProductInputForm() {
  const [agreeToTerms, setAgreeToTerms] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    if (!agreeToTerms) {
      alert("Please agree to the terms and conditions.");
      return;
    }

    setIsLoading(true);

    emailjs.sendForm('service_tzfhsrl', 'template_7djwlck', form.current, 'wSTJ2QIPy6DkNesLy')
      .then((result) => {
          console.log(result.text);
          alert("Message sent successfully!");
          form.current.reset();
          setAgreeToTerms(false);
      }, (error) => {
          console.log(error.text);
          alert("Failed to send message. Please try again.");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
   <>
   <section>
      <div className='container py-10 sm:py-16 md:flex gap-4 justify-between'>
        {/* Left side  */}
        <div className='md:w-1/2 flex justify-center items-center'>
          <div className='xl:h-72 bg-[#F5F5F5] flex flex-col justify-center items-center py-4 px-4 md:py-6 sm:py-0 xl:px-9 rounded-xl'>
            <h1 className='text-2xl xl:text-4xl font-bold nunito'>Our mission is to help our customers rise beyond the ordinary</h1>
            <p className='text-base font-normal inter pt-3'>Start your investment journey now and share your details, our relationship manager will contact you soon.</p>
          </div>
        </div>
        {/* Right part */}
        <div className='border border-[#E3E3E3] rounded-2xl px-4 xl:px-12 py-8 xl:py-12 my-16 sm:my-0'>
          <h3 className='inter xl:text-2xl text-lg md:text-xl font-bold text-[#161616]'>"Start Today! Contact Us!"</h3>
          <form ref={form} onSubmit={sendEmail}>
            <div className="my-4">
              <img src={name_icon} className='h-5 w-5 absolute mt-2.5 ml-2' alt="Name icon"/>
              <input
                name="user_name"
                type="text"
                placeholder="Full Name*"
                className="w-full px-9 py-2 border border-[#F3F3F3] rounded-md focus:outline-none focus:ring focus:ring-blue-200"
                required
              />
            </div>
            <div className="my-5">
              <img src={mail_icon} className='h-5 w-5 absolute mt-2.5 ml-2' alt="Email icon"/> 
              <input
                name="user_email"
                type="email"
                placeholder="Email Id*"
                className="w-full px-9 py-2 border border-[#F3F3F3] rounded-md focus:outline-none focus:ring focus:ring-blue-200"
                required
              />
            </div>
            <div className="my-5">
              <img src={phone} className='h-5 w-5 absolute mt-2.5 ml-2' alt="Phone icon"/>
              <input
                name="user_phone"
                type="number"
                placeholder="Mobile no*"
                className="w-full px-9 py-2 border border-[#F3F3F3] rounded-md focus:outline-none focus:ring focus:ring-blue-200 appearance-none"
                style={{MozAppearance: 'textfield'}}
                required
              />
            </div>
            <div className="my-5">
              <select
               
                name="topic"
                className="w-full px-3 py-2 border border-[#F3F3F3] rounded-md focus:outline-none focus:ring focus:ring-blue-200"
                required
              >
                <option value="" disabled selected>Select</option>
                <option value="N/A">N/A</option>
                <option value="Others">Others</option>
              </select>
            </div>
            <div className="my-5">
              <img src={referral} className='h-5 w-5 absolute mt-2.5 ml-2' alt="Referral icon"/>
              <input
                name="referral_code"
                type="text"
                placeholder="Referral Code"
                className="w-full px-9 py-2 border border-[#F3F3F3] rounded-md focus:outline-none focus:ring focus:ring-blue-200"
              />
            </div>
            <div className="my-5">
              <label className="inline-flex items-center">
                <input 
                  type="checkbox" 
                  className="form-checkbox text-custom rounded -mt-5 sm:mt-0" 
                  checked={agreeToTerms}
                  onChange={(e) => setAgreeToTerms(e.target.checked)}
                  required
                />
                <span className=" ml-3 xl:ml-2">
                  I agree to <Link to="#" className="text-custom underline">T&C</Link> and other <Link to="#" className="text-custom underline">applicable policies</Link>
                </span>
              </label>
            </div>
            <div>
              <button
                type="submit"
                className="sign_btn w-full bg-custom hover:bg-custom text-white font-bold py-2 px-4 rounded-md focus:outline-none focus:ring focus:ring-red-200"
                disabled={isLoading}
              >
                <span>{isLoading ? 'Submitting...' : 'Submit'}</span>
              </button>
            </div>
          </form>
        </div>
      </div>
   </section>
   </>
  )
}

export default OurProductInputForm;