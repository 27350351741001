import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Autoplay } from 'swiper/modules';
import { Link } from 'react-router-dom';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';
import image1 from '../assets/Images/Buy Your Dream House.webp';
import image2 from '../assets/Images/Save For Your Wedding.webp';
import image3 from '../assets/Images/Get Your Child Best Education.webp';
import image4 from '../assets/Images/Plan For Goals & Emergencies.webp';
import image5 from '../assets/Images/Plan For Joyful Retirement.webp';
import image6 from '../assets/Images/Wealth Journey.webp';
import image7 from '../assets/Images/Watch Your Wealth Grow With SIP.webp';
import image8 from '../assets/Images/Mutual-fund-bg.png';
import './Herosection.css';

function MutualHero() {
  const slides = [
    {
      title: "Mutual Funds",
      text: "Invest in professionally managed mutual funds to enjoy long-term capital appreciation and diversification. Experienced fund managers carefully select a diversified portfolio of stocks, bonds, and other securities aiming to maximize returns on your investments.",
      image: image8,
    },
    {
      title: "Buy Your Dream House",
      text: "Check How Much You Need To Save",
      image: image1,
    },
    {
      title: "Save For Your Wedding",
      text: "Check How Much You Need To Save",
      image: image2,
    },
    {
      title: "Get Your Child Best Education ",
      text: "Check How Much You Need To Save",
      image: image3,
    },
    {
      title: "Plan For Goals & Emergencies",
      text: "Check How Much You Need To Save",
      image: image4,
    },
    {
      title: "Plan For Joyful Retirement",
      text: "Check How Much You Need To Save",
      image: image5,
    },
    {
      title: "Start Your Wealth Journey ",
      text: "With Rs. 500/- Per month",
      image: image6,
    },
    {
      title: "Watch Your Wealth Grow With SIP",
      text: "",
      image: image7,
    }
  ];

  return (
    <section className=''>
      <div className=' xl:h-96 h-80'>
      <Swiper
        modules={[Pagination, Autoplay]}
        spaceBetween={50}
        slidesPerView={1}
        pagination={{ clickable: true }}
        autoplay={{ delay: 3000, disableOnInteraction: false }}
        className="mySwiper h-full custom-pagination"
      >
          {slides.map((slide, index) => (
            <SwiperSlide key={index} className="relative h-full">
              <img src={slide.image} alt={slide.title} className="absolute inset-0 w-full h-full object-cover" />
              <div className="absolute inset-0  flex flex-col justify-center text-white container">
                <h2 className="xl:text-[50px] text-2xl font-bold nunito xl:mb-6">{slide.title}</h2>
                <div className='w-3/4 xl:w-1/2'> 
                 <p className="xl:text-lg text-sm font-normal inter pb-4">{slide.text}</p>
                </div>
                <Link to="https://clientlogin.cheerbull.com/" target="_blank">
                  <button className='sign_btn w-fit lg:text-lg text-sm font-bold roboto bg-custom lg:px-4 px-4  py-1.5 rounded-lg text-white'>
                  <span>Start Investing Now</span>
                  </button>
                </Link>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </section>
  );
}

export default MutualHero;
