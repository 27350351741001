import React from 'react'
import travel_icon from '../assets/Icons/travel-insurance-icon.svg'
import vehicle_icon from '../assets/Icons/Vehicle-insurance-icon.svg'
import health_icon from '../assets/Icons/Health-insurance-icon.svg'
import home_icon from '../assets/Icons/Home-insurance-icon.svg'
import team_icon from '../assets/Icons/Team-insurance-icon.svg'
import personal_icon from '../assets/Icons/Personal-insurance-icon.svg'
function SoldByPOSp() {
  return (
   <section>
    <div className='container bg-[#F5F5F5] py-12'>
            <h1 className='text-[#323232] text-4xl font-bold text-center nunito'>What are the products that can be<br/> sold by POSp?</h1>
           <div className='grid grid-cols-2 xl:gap-12 xl:grid-cols-3 xl:py-16 xl:px-44'>
              <div className='bg-white xl:w-64 xl:h-36 rounded-lg flex flex-col gap-2 justify-center items-center border shadow-md'>
                  <img src={travel_icon}/>
                  <p className='text-[#323232] text-lg font-semibold plus-jakarta-sans'>Travel Insurance Policy</p>
              </div>
              <div className='bg-white xl:w-64 xl:h-36 rounded-lg flex flex-col gap-2 justify-center items-center border shadow-md'>
                  <img src={vehicle_icon}/>
                  <p className='text-[#323232] text-lg font-semibold plus-jakarta-sans'>Vehicle Insurance Policy</p>
              </div>
              <div className='bg-white xl:w-64 xl:h-36 rounded-lg flex flex-col gap-2 justify-center items-center border shadow-md'>
                  <img src={health_icon}/>
                  <p className='text-[#323232] text-lg font-semibold plus-jakarta-sans'>Health Insurance Policy</p>
              </div>
              <div className='bg-white xl:w-64 xl:h-36 rounded-lg flex flex-col gap-2 justify-center items-center border shadow-md'>
                  <img src={home_icon}/>
                  <p className='text-[#323232] text-lg font-semibold plus-jakarta-sans'>Home Insurance Policy</p>
              </div>
              <div className='bg-white xl:w-64 xl:h-36 rounded-lg flex flex-col gap-2 justify-center items-center border shadow-md'>
                  <img src={team_icon}/>
                  <p className='text-[#323232] text-lg font-semibold plus-jakarta-sans'>Term Insurance Policy</p>
              </div>
              <div className='bg-white xl:w-64 xl:h-36 rounded-lg flex flex-col gap-2 justify-center items-center border shadow-md'>
                  <img src={personal_icon}/>
                  <p className='text-[#323232] text-lg font-semibold plus-jakarta-sans'>Personal Accident Policy</p>
              </div>
           </div> 
    </div>
   </section>
  )
}

export default SoldByPOSp