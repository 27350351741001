import React from 'react'
import P2Phero from '../Components/P2Phero'
import BenifitOfP2P from '../Components/BenifitOfP2P'
import JoinUs from '../Components/JoinUs'
import FeaturedOn from '../Components/FeaturedOn'
import Testimonials from '../Components/Testimonials'
import P2PFaq from '../Components/P2PFaq'

function P2Ppage() {
  return (
    <>
        <P2Phero/>
        <BenifitOfP2P/>
        <JoinUs/>
        {/* <FeaturedOn/> */}
        <P2PFaq/>
        <Testimonials/>
    </>
  )
}

export default P2Ppage