import React from 'react'

function BenifitOfTaxBond() {
  return (
    <section className='typesofaif_bg'>
    <div className='container py-8 xl:py-12'>
            <h1 className='xl:text-4xl text-2xl md:text-3xl font-bold nunito text-white text-center pb-8 sm:pb-0'>Benefits of Tax-Free Bonds</h1>
            <div className='grid md:grid-cols-3 gap-7 md:py-16'>
                <div className='flex flex-col justify-center w-full xl:h-48 h-48 md:h-64 rounded-xl px-6 bg-[#D9D9D933] text-white'>
                    <p className='xl:text-3xl text-xl font-bold nunito'>Tax exemption</p>
                    <p className='text-base font-normal inter'>The primary advantage of tax-free bonds is that the interest income is entirely exempt from income tax.</p>
                </div>
                <div className='flex flex-col justify-center w-full xl:h-48 h-48 md:h-64 rounded-xl px-6 bg-[#D9D9D933] text-white'>
                    <p className=' xl:text-3xl text-xl font-bold nunito'>Stable income stream</p>
                    <p className=' text-base font-normal inter'>Tax-free bonds offer a stable income stream through regular coupon payments, providing a predictable source of revenue.</p>
                </div>
                <div className='flex flex-col justify-center w-full xl:h-48 h-48 md:h-64 rounded-xl px-6 bg-[#D9D9D933] text-white'>
                    <p className=' xl:text-3xl text-xl font-bold nunito'>Wealth creation potential</p>
                    <p className=' text-base font-normal inter'>Over the long term, tax-free bonds have the potential
                    for capital appreciation, potentially increasing the value of your investment.</p>
                </div>
            </div>
    </div>
   </section>
  )
}

export default BenifitOfTaxBond