import React from 'react'
import TaxFreeHero from '../Components/TaxFreeHero'
import BenifitOfTaxBond from '../Components/BenifitOfTaxBond'
import ImplicationsofTaxBonds from '../Components/ImplicationsofTaxBonds'
import JoinUs from '../Components/JoinUs'
import FeaturedOnTwo from '../Components/FeaturedOnTwo'

import Testimonials from '../Components/Testimonials'
import TaxBondFaq from '../Components/TaxBondFaq'

function TaxFreeBond() {
  return (
    <>
        <TaxFreeHero/>
        <BenifitOfTaxBond/>
        <ImplicationsofTaxBonds/>
        <JoinUs/>
        {/* <FeaturedOnTwo/> */}
        <TaxBondFaq/>
        <Testimonials/>
    </>
  )
}

export default TaxFreeBond