import React from 'react'

function BenefitsofPms() {
  return (
    <section className='typesofaif_bg'>
    <div className='container py-12'>
            <h1 className='lg:text-4xl text-2xl font-bold nunito text-white text-center pb-2'>Advantages of Portfolio Management Services</h1>
            <div className='grid md:grid-cols-3 gap-7 lg:py-16 py-8'>
                <div className='flex flex-col justify-center w-full h-52 md:h-96 lg:h-72 xl:h-52 rounded-xl px-6 bg-[#D9D9D933] text-white'>
                    <p className='lg:text-3xl text-xl font-bold nunito'>Professional Expertise</p>
                    <p className='text-base font-normal inter'>PMS provides access to experienced and professional portfolio managers who have in-depth knowledge and expertise in managing investments and effective portfolio risk management.</p>
                </div>
                <div className='flex flex-col justify-center w-full h-52 md:h-96 lg:h-72 xl:h-52 rounded-xl px-6 bg-[#D9D9D933] text-white'>
                    <p className=' lg:text-3xl text-xl font-bold nunito'>Diversification</p>
                    <p className=' text-base font-normal inter'>Diversification helps reduce risk by minimizing the impact of individual stock or sector performance on the overall portfolio and effective stock portfolio management.</p>
                </div>
                <div className='flex flex-col justify-center w-full h-52 md:h-96 lg:h-72 xl:h-52 rounded-xl px-6 bg-[#D9D9D933] text-white'>
                    <p className=' lg:text-3xl text-xl font-bold nunito'>Portfolio Management</p>
                    <p className=' text-base font-normal inter'>They regularly assess market conditions, make necessary adjustments, and capitalize on emerging opportunities while mitigating risk. This is an effective investment portfolio management system.</p>
                </div>
            </div>
    </div>
   </section>
  )
}

export default BenefitsofPms