import React, { useState } from 'react';
import right_img from '../assets/Images/Corparate-fd-hero-right-new.webp';
import { Link } from 'react-router-dom';

function CorporateFdHero() {
  const [hovered, setHovered] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });

  const handleLinkClick = (event) => {
    event.preventDefault(); // Prevent the default link behavior
    window.open(
      'https://www.bajajfinserv.in/investments/fixed-deposit?PartnerCode=228742&utm_source=PartnerOne&utm_medium=B2B&utm_campaign=PartnerOne#',
      '_blank',
      'noopener,noreferrer'
    );
  };

  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };

  const handleMouseMove = (e) => {
    const rect = e.currentTarget.getBoundingClientRect();
    setPosition({ x: e.clientX - rect.left, y: e.clientY - rect.top });
  };

  return (
    <>
      <section className='corporate_fd_bg'>
        <div className='container py-10 xl:py-24 xl:mt-3'>
          <div className='xl:w-1/2 md:w-9/12'>
            <h1 className='text-white nunito font-bold text-3xl xl:text-5xl pb-3'>Fixed Deposit</h1>
            <p className='text-white text-base inter font-normal xl:text-lg pb-4'>
              FDs are an investment instrument that allows you to earn fixed returns on your investment amount. They are issued by various companies for varying tenures and interest rates. FDs offer a fixed rate of return that typically ranges from 3% to 9% per annum*, which helps in securing your investment.
            </p>
            <Link to="https://clientlogin.cheerbull.com/" target="_blank">
              <button className='sign_btn px-6 py-1.5 rounded-lg text-lg font-bold roboto bg-custom text-white'>
                <span>Get Started</span>
              </button>
            </Link>
          </div>
        </div>
      </section>
      {/* Second part */}
      <section className='container md:flex gap-3 py-8 md:py-12 xl:py-16'>
        <div className='xl:w-3/5 md:w-full flex flex-col justify-center md:w-'>
          <h2 className='nunito text-[#3C3C3C] xl:text-4xl font-bold md:text-3xl text-2xl'>What are Fixed Deposits?</h2>
          <p className='text-[#6A6A6A] inter font-base font-normal py-3'>
            FDs are financial products where companies raise funds from investors by issuing FDs for fixed periods, ranging from a few months to a few years. They offer a fixed interest rate on the investment amount, usually paid out either monthly, quarterly, semi-annually, yearly, or at maturity. They are useful in creating potential wealth creation opportunities by earning stable returns. Additionally, corporate FDs provide diversification benefits to your portfolio, reducing your overall risk.
          </p>
          <p className='text-[#6A6A6A] inter font-base font-normal'>
            They're ideal for individuals who are looking for higher interest rates on their investments as compared to traditional savings instruments.
          </p>
        </div>
        <div
          className='xl:w-2/5 mt-6 sm:mt-0 pb-5 sm:pb-0 relative'
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          onMouseMove={handleMouseMove}
        >
          <Link to="#" onClick={handleLinkClick}>
            <img src={right_img} alt="Description of Image" />
          </Link>
          {hovered && (
            <div
              className="absolute text-white bg-black bg-opacity-75 p-1 rounded pointer-events-none"
              style={{
                left: position.x + 60, // Offset the text from the cursor
                top: position.y + 20,
                transform: 'translate(-50%, -50%)',
              }}
            >
              Click here
            </div>
          )}
        </div>
      </section>
    </>
  );
}

export default CorporateFdHero;
